// Devices responsive //

@mixin laptop {
    @media only screen and (min-width: #{$laptop-min}) and (max-width: #{$laptop-max}) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (min-width: #{$tablet-min}) and (max-width: #{$tablet-max}) {
        @content;
    }
}

@mixin mobile {
    @media only screen and (max-width: #{$mobile-max}) {
        @content;
    }
}

@mixin media($keys...){
    @each $key in $keys { 
      @media (min-width: map-get($breakpoints, $key)){
        @content
      } 
    }
}

// Typography - Desktop//

@mixin heading-01 { 
	font-family: "Gotham-Book";
    font-size: 4.25rem;
    line-height: 4.67rem;
    letter-spacing: 0;
}

@mixin heading-02 { 
	font-family: "Gotham-Book";
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 0;
}

@mixin heading-03 { 
	font-family: "Gotham-Book";
    font-size: 2.27rem;
    line-height: 3rem;
    letter-spacing: 0;
}

@mixin heading-04 { 
	font-family: "Gotham-Book";
    font-size: 2.25rem;
    line-height: 2.5rem;
    letter-spacing: 0;
}

@mixin heading-05 { 
	font-family: "Gotham-Book";
    font-size: 1.88rem;
    line-height: 2.13rem;
    letter-spacing: 0;
}

@mixin heading-06 { 
	font-family: "Gotham-Book";
    font-size: 1.5rem;
    line-height: 1.75rem;
    letter-spacing: 0;
}

@mixin text-01 { 
	font-family: "Gotham-Light";
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0;
}

@mixin text-02 { 
	font-family: "Gotham-Book";
    font-size: 1.13rem;
    line-height: 1.75rem;
    letter-spacing: 0;
}

@mixin label-01 { 
	font-family: "Gotham-Medium";
    font-size: 1rem;
    line-height: 1.25rem;
    letter-spacing: 1px;
    text-transform: uppercase;
}