@import './variables.scss';
@import './mixins.scss';

.home_warpper{
    .banner{
        background-image: url('../../public/assets/banners/home_banner.jpg');
        background-size: 100%;
        background-repeat: no-repeat;
        height: calc(504px - 70px);
        position: relative;
        background-position-y: -40px;
        @include mobile {
            background-size: 100%;
            height: 276px;
            background-position-y: -30px;
            background-size: cover;
        }
        .content_layer{
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            .text_block{
                max-width: 1440px;
                margin: 0 auto;
                padding: 0 1rem;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                gap: 1.5rem;
                @include laptop{
                    max-width: 1000px;
                    padding: 0px;
                }
                @include mobile{
                    max-width: 100%;
                    width: 100%;
                }
                .user_info{
                    width: 700px;
                    height: 60px;
                    padding: 17px 44px;
                    background-color: $secondary;
                    color: $white;
                    display: flex;
                    justify-content: space-between;
                    user-select: none;
                    border-radius: 3px;
                    margin-top: 80px;
                    @include laptop{
                        margin-top: 120px;
                    }
                    @include mobile{
                        width: 100%;
                        padding: 5px 10px;
                        margin-top: 20px;
                        height: 50px;
                        align-items: center;
                    }
                    .user_name{
                        @include mobile{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 25px;
                        }
                        h1{
                            font-family: "Gotham-Book";
                            font-weight: 325;
                            line-height: 25px;
                            font-size: 32px;
                            color: $white;
                            margin: 0;
                            @include laptop {
                                font-size: 28px;
                            }
                            @include mobile{
                                font-size: 18px;
                            }
                            .link{
                                text-decoration: none;
                                color: $white;
                                &::after{
                                    content: '';
                                    width: 0;
                                    height: 1px;
                                    background-color: $white;
                                    display: block;
                                    margin-top: 8px;
                                    transition: all .3s ease-in-out;
                                }
                                &:hover{
                                    &::after{
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                    .extra_info{
                        display: flex;
                        justify-content: flex-end;
                        gap: 2rem;
                        @include mobile{
                            gap: 1rem;
                            height: 25px;
                        }
                        .date{
                            text-transform: capitalize;
                        }
                        h1{
                            font-family: "Gotham-Book";
                            font-weight: 325;
                            font-size: 20px;
                            color: $white;
                            margin: 0;
                            display: flex;
                            justify-content: flex-start;
                            gap: .5rem;
                            align-items: center;
                            align-content: center;
                            @include laptop {
                                font-size: 18px;
                            }
                            @include mobile{
                                font-size: 14px;
                            }
                            svg{
                                width: 18px;
                                aspect-ratio: 1/1;
                                @include mobile{
                                    width: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .banner_search{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        gap: 1rem;
        position: relative;
        z-index: 20;
        max-width: 1168px;
        margin: 0 auto;
        padding: 0 1rem;
        margin-top: -150px;
        @include laptop{
            max-width: 1000px;
        }
        @include mobile{
            max-width: 100%;
            gap: 1.75rem;
        }
        .search_box{
            width: 100%;
            max-width: 1168px;
            background-color: rgba(0, 0, 0, 0.3);
            height: 132px;
            margin: -3rem 0 0 0;
            border-radius: $border_radius;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            align-content: center;
            border: none;
            padding: 50px 120px 40px 120px;
            @include mobile{
                padding: 40px 20px 30px 20px;
                height: 110px;
            }
            form{
                width: 100%;
                position: relative;
                display: flex;
                justify-content: center;
                .input_group{
                    width: 100%;
                    max-width: 929px;
                    min-width: 929px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    gap: .25rem;
                    position: relative;
                    width: 100%;
                    border-radius: 3px;
                    @include laptop {
                        max-width: 829px;
                        min-width: 829px;
                    }
                    @include mobile{
                        min-width: 100%;
                        max-width: 100%;
                    }
                    .fluent_label{
                        position: absolute;
                        left: 0;
                        top: -28px;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        align-content: center;
                        gap: 10px;
                        height: 28px;
                        width: 100%;
                        .title{
                            font-family: 'Gotham-Book';
                            text-transform: uppercase;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 18px;
                            letter-spacing: 0.2px;
                            color: $white;
                            width: fit-content;
                            width: -moz-fit-content;
                            @include mobile{
                                font-size: 11px;
                                line-height: 14px;
                            }
                        }
                    }
                    .fluent_input{
                        width: 100%;
                        height: 44px;
                        padding: 13px 50px 13px 24px;
                        border: 1px solid $grey-04;
                        font-weight: 325;
                        color: $grey-05;
                        border-radius: 3px;
                        @include text-01;
                        @include mobile{
                            font-size: 13px;
                        }
                        &:focus, &:active{
                            outline: none;
                            border-color: $black;
                        }
                        &:hover {
                            outline: none;
                            border-color: $black;
                        }
                    }
                    .input_icon{
                        right: 10px;
                        top: 50%;
                        width: 34px;
                        position: absolute;
                        aspect-ratio: 1/1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        align-content: center;
                        transition: all .3s ease-in-out;
                        border-radius: 50%;
                        transform: translateY(-50%);
                        &:hover{
                            background-color: $secondary-01;
                            cursor: pointer;
                        }
                        .icon{
                            width: 34px;
                            aspect-ratio: 1/1;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            align-content: center;
                            svg{
                                width: 20px;
                                aspect-ratio: 1/1;
                                path{
                                    fill: $secondary
                                } 
                            }
                        }
                    }
                }
            }
        }
        .home_stats{
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-content: center;
            width: 100%;
            gap: 35px;
            @include laptop{
                gap: 20px;
            }
            @include mobile{
                flex-direction: column;
                gap: 1.5rem;
            }
            .stat{
                width: 50%;
                height: 96px;
                background-color: $white;
                border: 1px solid $grey-02;
                border-radius: $border_radius;
                padding: 32px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                @include mobile{
                    width: 100%;
                    height: 80px;
                    padding: 14px 13px;
                    margin-top: -15px;
                }
                .error{
                    display: flex;
                    justify-content: flex-start;
                    align-content: center;
                    align-items: center;
                    gap: 10px;
                    .icon{
                        svg{
                            width: 25px;
                            aspect-ratio: 1/1;
                            path{
                                fill: $secondary;
                            }
                        }
                    }
                    .error_text{
                        font-family: 'Gotham-Light';
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 20px;
                        color: $primary;
                        @include mobile{
                            font-size: 14px;
                            line-height: 16px;
                        }
                    }
                }
                &.booking{
                    .title{
                        span{
                            text-transform: uppercase;
                            font-family: 'Gotham-Book';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 20px;
                            color: $primary;
                            width: 100px;
                            display: block;
                            @include laptop {
                                font-size: 12px;
                                line-height: 16px;
                            }
                            @include mobile{
                                font-size: 12px;
                                line-height: 14px;
                            }
                        }
                    }
                    .logo{
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        align-content: center;
                        gap: 15px;
                        svg{
                            width: 50px;
                            aspect-ratio: 1/1;
                            @include laptop {
                                width: 45px;
                            }
                            @include mobile{
                                width: 45px;
                            }
                        }
                        .stat_info{
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            .points{
                                font-family: 'Gotham-Medium';
                                font-style: normal;
                                font-weight: 500;
                                font-size: 32px;
                                line-height: 40px;
                                color: $grey-06;
                                @include laptop {
                                    font-size: 28px;
                                    line-height: 34px;
                                }
                                @include mobile{
                                    font-size: 24px;
                                    line-height: 28px;
                                }
                            }
                            .date{
                                font-family: 'Gotham-Light';
                                font-weight: 325;
                                font-size: 16px;
                                line-height: 20px;
                                color: $black;
                                text-transform: capitalize;
                                @include laptop {
                                    font-size: 14px;
                                    line-height: 16px;
                                }
                                @include mobile{
                                    font-size: 12px;
                                    line-height: 14px;
                                }
                            }
                        }
                    }
                    .stat_results{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-end;
                        gap: 7px;
                        .new_points{
                            font-family: 'Gotham-Book';
                            font-style: normal;
                            font-weight: 325;
                            font-size: 28px;
                            line-height: 24px;
                            @include laptop {
                                font-size: 24px;
                                line-height: 20px;
                            }
                            @include mobile{
                                font-size: 16px;
                                line-height: 18px;
                            }
                        }
                        .new_info{
                            display: flex;
                            justify-content: flex-end;
                            align-items: flex-end;
                            font-family: 'Gotham-Light';
                            font-style: normal;
                            font-weight: 325;
                            font-size: 16px;
                            color: $black;
                            gap: 8px;
                            svg{
                                width: 14px;
                                aspect-ratio: 1/1;
                                @include laptop {
                                    width: 12px;
                                }
                                @include mobile{
                                    width: 10px;
                                }
                            }
                            span{
                                font-family: 'Gotham-Light';
                                font-style: normal;
                                font-weight: 325;
                                font-size: 16px;
                                color: $black;
                                line-height: 12px;
                                @include laptop {
                                    font-size: 14px;
                                    line-height: 10px;
                                }
                                @include mobile{
                                    font-size: 12px;
                                    line-height: 14px;
                                }
                            }
                        }
                        &.down{
                            .new_points{
                                color: $secondary;
                            }
                            .new_info svg path{
                                fill: $secondary;
                            }
                        }
                        &.up{
                            .new_points{
                                color: $success-02;
                            }
                            .new_info svg {
                                transform: rotate(180deg);
                                path{
                                    fill: $success-02;
                                }
                            }
                        }
                    }
                }
                &.forbes{
                    .header_title{
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: center;
                        .title{
                            font-family: 'Gotham-Medium';
                            font-weight: 500;
                            font-size: 28px;
                            line-height: 28px;
                            text-transform: capitalize;
                            color: $grey-06;
                            @include laptop {
                                font-size: 24px;
                                line-height: 26px;
                            }
                            @include mobile{
                                font-size: 18px;
                                line-height: 28px;
                            }
                        }
                        .subtitle{
                            font-family: 'Gotham-Light';
                            font-style: normal;
                            font-weight: 325;
                            font-size: 16px;
                            line-height: 20px;
                            color: $black;
                            @include laptop {
                                font-size: 14px;
                                line-height: 18px;
                            }
                            @include mobile{
                                font-size: 12px;
                                line-height: 16px;
                            }
                        }
                    }
                    .image{
                        width: 192px;
                        height: 73px;
                        @include laptop {
                            width: 150px;
                            height: 60px;
                        }
                        @include mobile{
                            width: 120px;
                            height: 50px;
                        }
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }
            }
        }
    }
    .home_content{
        margin-top: 50px;
        @include mobile{
            margin-top: 20px;
        }
        .stories{
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;
            position: relative;
            z-index: 20;
            max-width: 1440px;
            margin: 0 auto;
            padding: 0 1rem;
            margin-bottom: 50px;
            @include laptop {
                max-width: 1200px;
            }
            @include mobile{
                flex-direction: column;
                max-width: 100%;
                width: 100%;
            }
            .section{
                border: 1px solid $grey-02;
                border-radius: $border_radius;
                background-color: $white;
                margin: 0 !important;
                @include mobile{
                    width: 100% !important;
                    height: 464px;
                }
                &.stories{
                    width: calc(65% - 10px);
                    padding: 0px;
                    height: 100%;
                    @include laptop {
                        width: calc(60% - 10px);
                    }
                    @include mobile{
                        width: 100%;
                        height: auto;
                        max-height: -moz-fit-content;
                        max-height: fit-content;
                        min-height: auto;
                    }
                    .carousel{
                        width: 100%;
                        height: 100%;
                        display: block;
                        position: relative;
                        border-radius: $border_radius;
                        .main_title{
                            position: absolute;
                            top: 30px;
                            left: 30px;
                            z-index: 25;
                            max-width: 395px;
                            @include mobile{
                                top: 250px;
                                left: 16px;
                            }
                            @include laptop {
                                max-width: 235px
                            }
                            .title{
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                gap: 10px;
                                span{
                                    font-family: 'Gotham-Book';
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 14px;
                                    line-height: 20px;
                                    text-transform: uppercase;
                                    color: $grey-06;
                                    white-space: nowrap;
                                    @include mobile{
                                        font-size: 10px;
                                        line-height: 12px;
                                    }
                                }
                                .storie_title{
                                    white-space: pre-wrap;
                                    @include laptop{
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 3;
                                        -webkit-box-orient: vertical;
                                    }
                                }
                            }
                        }
                        .direction_arrows{
                            display: flex;
                            width: 100%;
                            height: 64px;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            align-content: center;
                            position: absolute;
                            bottom: 50%;
                            right: 50%;
                            transform: translate(50%, 50%);
                            display: flex;
                            gap: 15px;
                            z-index: 50;
                            padding: 0px 32px;
                            @include laptop{
                                bottom: 60%;
                                transform: translate(50%, 60%);
                                padding: 0px 20px;
                            }
                            @include mobile {
                                bottom: 70%;
                                transform: translate(50%, 70%);
                                padding: 0px 10px;
                            }
                            .arrow{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                align-content: center;
                                width: 48px;
                                height: 64px;
                                background-color: rgba(255, 255, 255, 0.5);
                                border-radius: $border_radius;
                                cursor: pointer;
                                @include mobile{
                                    width: 32px;
                                    height: 40px;
                                }
                                @include laptop{
                                    width: 40px;
                                    height: 48px;
                                }
                                &.disabled{
                                    background-color: rgba(255, 255, 255, 0.5);
                                    &:hover{
                                        background-color: rgba(255, 255, 255, 0.5);
                                    }
                                    svg{
                                        path{
                                            fill: $grey-03;
                                        }
                                    }
                                }
                                &:hover{
                                    background-color: rgba(255, 255, 255, 0.7);
                                }
                                &.left{
                                    transform: rotate(180deg);
                                }
                                svg{
                                    height: 28px;
                                    aspect-ratio: 1/1;
                                    @include mobile {
                                        height: 20px;
                                    }
                                }
                            }
                        }
                        .controls{
                            position: absolute;
                            bottom: 40px;
                            right: 40px;
                            display: flex;
                            gap: 15px;
                            z-index: 20;
                            @include laptop{
                                bottom: 45px;
                                right: 30px;
                                gap: 10px;
                            }
                            @include mobile{
                                top: 215px;
                                right: 50%;
                                bottom: unset;
                                transform: translateX(50%);
                                height: 12px;
                                gap: 20px;
                                padding: 0px 10px;
                                width: 100%;
                            }
                            .control{
                                width: 12px;
                                height: 12px;
                                aspect-ratio: 1/1;
                                border-radius: 50%;
                                background-color: $white;
                                cursor: pointer;
                                @include laptop{
                                    width: 10px;
                                    height: 10px;
                                }
                                @include mobile{
                                    width: 8px;
                                    height: 8px;
                                }
                                &.active{
                                    background-color: $secondary;
                                }
                            }
                        }
                        .slides{
                            height: 100%;
                            overflow: hidden;
                            border-radius: $border_radius;
                            .inner{
                                overflow-x: scroll;
                                overflow-y: hidden;
                                white-space: nowrap;
                                display: flex;
                                width: 100%;
                                height: 100%;
                                scrollbar-width: none;
                                scroll-behavior: smooth;
                                scroll-snap-type: both mandatory;
                                scroll-padding: 1rem;
                                list-style: none;
                                padding: 0;
                                margin: 0px !important;
                                &::-webkit-scrollbar {
                                    display: none;
                                }
                                @include mobile{
                                    width: 100%;
                                    height: 464px;
                                }
                                &.active{
                                    cursor: -webkit-grabbing;
                                    cursor: grabbing;
                                    scroll-behavior: smooth;
                                    scroll-snap-type: both mandatory;
                                }
                                .slide{
                                    min-width: 100%;
                                    width: 100%;
                                    height: 100%;
                                    position: relative;
                                    display: inline-flex;
                                    scroll-snap-align: center;
                                    -webkit-user-select: none;
                                    -khtml-user-select: none;
                                    -moz-user-select: none;
                                    -o-user-select: none;
                                    user-select: none;
                                    background-color: $primary;
                                    @include mobile{
                                        width: 100%;
                                        height: 464px;
                                    }
                                    .image{
                                        width: 100%;
                                        height: 100%;
                                        position: absolute;
                                        z-index: 10;
                                        display: flex;
                                        justify-content: flex-end;
                                        img{
                                            width: 100%;
                                            height: 100%;
                                            border-radius: $border_radius;
                                            object-fit: contain;
                                            @include laptop {
                                                object-fit: contain;
                                            }
                                            @include mobile{
                                                height: 100%;
                                                object-fit: contain;
                                            }
                                        }
                                        
                                    }
                                    .content{
                                        position: relative;
                                        z-index: 20;
                                        width: 446px;
                                        height: 100%;
                                        background-color: rgba(255, 255, 255, 0.9);
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: flex-end;
                                        padding: 30px 30px 40px 30px;
                                        gap: 15px;
                                        @include laptop {
                                            width: 270px
                                        }
                                        @include mobile{
                                            width: 100%;
                                            padding: 45px 16px 18px 16px;
                                            height: 224px;
                                            bottom: 0px;
                                            position: absolute;
                                            gap: 8px;
                                        }
                                        .title_content{
                                            font-family: 'Gotham-Book';
                                            font-style: normal;
                                            font-weight: 325;
                                            font-size: 36px;
                                            line-height: 40px;
                                            color: $grey-06;
                                            width: 100%;
                                            white-space: pre-line;
                                            @include laptop {
                                                font-size: 28px;
                                                line-height: 28px;
                                            }
                                            @include mobile{
                                                font-size: 20px;
                                                line-height: 26px;
                                                font-weight: 'Gotham-Light';
                                                margin: 0px;
                                            }
                                        }
                                        .description{
                                            font-family: 'Gotham-Light';
                                            font-style: normal;
                                            font-weight: 325;
                                            font-size: 16px;
                                            line-height: 20px;
                                            color: $grey-06;
                                            width: 100%;
                                            white-space: pre-line;
                                            display: -webkit-box;
                                            -webkit-line-clamp: 3;
                                            -webkit-box-orient: vertical;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            @include mobile{
                                                font-size: 14px;
                                                line-height: 20px;  
                                                -webkit-line-clamp: 2;
                                                margin: 0px;
                                            }
                                        }
                                        .see_more{
                                            margin-top: 10px;
                                            font-family: 'Gotham-Light';
                                            font-style: normal;
                                            font-weight: 325;
                                            font-size: 16px;
                                            line-height: 24px;
                                            color: $grey-06;
                                            cursor: pointer;
                                            transition: all .3s;
                                            text-decoration: none;
                                            @include mobile{
                                                font-size: 12px;
                                                line-height: 24px;
                                                margin: 0px;
                                            }
                                            svg{
                                                width: 10px;
                                                aspect-ratio: 1/1;
                                                margin-left: 10px;
                                                transition: all .3s ease-in-out;
                                                @include mobile{
                                                    width: 8px;
                                                }
                                            }
                                            &:hover{
                                                color: $secondary;
                                                svg{
                                                    margin-left: 20px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &.links{
                    width: calc(35% - 10px);
                    padding: 30px;
                    @include laptop {
                        width: calc(40% - 10px);
                        padding: 20px
                    }
                    @include mobile{
                        height: auto;
                        max-height: fit-content;
                        min-height: auto;
                        padding: 30px 16px 16px 16px;
                    }
                    .section_title{
                        margin-bottom: 20px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        align-content: center;
                        gap: 16px;
                        h2{
                            font-family: 'Gotham-Book';
                            font-style: normal;
                            font-weight: 325;
                            font-size: 32px;
                            line-height: 36px;
                            color: $grey-06;
                            margin: 0px;
                            @include laptop{
                                font-size: 28px;
                            }
                        }
                        .info_icon{
                            width: 21px;
                            aspect-ratio: 1/1;
                            @include laptop {
                                width: 18px;
                            }
                            svg{
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                    .cards{
                        display: grid;
                        grid-template-columns: repeat(2, minmax(120px, 230px));
                        column-gap: 25px;
                        row-gap: 20px;
                        justify-items: stretch;
                        @include laptop{
                            column-gap: 20px;
                            row-gap: 15px;
                        }
                        @include mobile{
                            column-gap: 16px;
                            row-gap: 20px;
                        }
                        .link{
                            width: 100%;
                            height: 72px;
                            background-color: $white;
                            border-radius: 3px;
                            border: 1px solid $grey-02;
                            padding: 16px 6px 16px 10px;
                            text-decoration: none;
                            color: $grey-06;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 10px;
                            transition: all .3s ease-in-out;
                            cursor: pointer;
                            justify-self: flex-start;
                            min-width: 120px;
                            max-width: 230px;
                            @include mobile{
                                height: 90px;
                                width: 100%;
                                flex-direction: column;
                                justify-content: space-between;
                                align-items: center;
                                text-align: center;
                                padding: 8px;
                                gap: 12px;
                            }
                            &:hover{
                                border: 1px solid $grey-03;
                            }
                            .link_info{
                                width: calc(100% - 50px);
                                @include mobile {
                                    width: 100%;
                                    text-align: center;
                                    justify-content: center;
                                    align-items: center;
                                    display: flex;
                                }
                                @include laptop{
                                    overflow: hidden;
                                }
                                .link_title{
                                    font-family: 'Gotham-Light';
                                    font-style: normal;
                                    font-weight: 325;
                                    font-size: 14px;
                                    line-height: 20px;
                                    text-transform: uppercase;
                                    color: $grey-06;
                                    width: 100%;
                                    display: block;
                                    @include laptop {
                                        line-height: 16px;
                                        overflow: hidden;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 2;
                                        -webkit-box-orient: vertical;
                                        -webkit-box-align: center;
                                    }
                                    @include mobile{
                                        line-height: 16px;
                                        width: 100%;
                                        height: 30px;
                                    }
                                }
                            }
                            .link_icon{
                                width: 40px;
                                height: 40px;
                                aspect-ratio: 1/1;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                @include mobile{
                                    width: 32px;
                                    height: 32px;
                                }
                                img{
                                    width: auto;
                                    max-width: 100%;
                                    height: 100%;
                                    aspect-ratio: auto 1;
                                    @include mobile{
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                            }
                            &.image{
                                padding: 16px !important;
                                .link_icon{
                                    width: 100% !important;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    @include mobile{
                                        width: 100%;
                                        height: 100%;
                                    }
                                    img{
                                        width: 100%;
                                        max-height: 60px;
                                        height: auto;
                                        object-fit: contain;
                                    }
                                }
                                .link_info{
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
        .documents{
            background-color: $white !important;
            margin-top: 0px !important;
            margin-bottom: -6rem !important;
            @include mobile{
                margin-bottom: -3rem !important;
            }
            .wrapper{
                .block_title{
                    color: $grey-06 !important;
                }
                .requests_list{
                    .list{
                        .item{
                            border-color: $grey-03 !important;
                            &:hover{
                                border-color: $grey-04 !important;
                            }
                            .wrapper{
                                color: $grey-06 !important;
                                .icon{
                                    background-color: $grey-02 !important;
                                }
                                .card_title{
                                    color: $grey-06 !important;
                                }
                                .description{
                                    color: $grey-06 !important;
                                }
                                .see_more{
                                    color: $grey-06 !important;
                                }
                            }
                        }
                    }
                    .see_more{
                        a{
                            color: $grey-06 !important;
                        }
                        svg path{
                            fill: $grey-06 !important;
                        }
                        &:hover{
                            a{
                                color: rgba(0,0,0,0.4) !important;
                            }
                            svg{
                                path{
                                    fill: rgba(0,0,0,0.4) !important;
                                }
                            }
                        }
                    }
                }
            }
        }
        .requests, .documents{
            background-color: $grey-04;
            width: 100%;
            height: 530px;
            @include mobile{
                height: auto;
                min-height: 530px;
            }
            &.last_item {
                margin-bottom: -6rem;
                @include mobile{
                    margin-bottom: -3rem;
                }
            }
            .wrapper{
                position: relative;
                z-index: 20;
                max-width: 1440px;
                margin: 0 auto;
                padding: 4rem 1rem 2rem 1rem;
                @include laptop{
                    max-width: 1200px;
                }
                .block_title{
                    font-family: 'Gotham-Book';
                    font-style: normal;
                    font-weight: 325;
                    font-size: 36px;
                    line-height: 40px;
                    color: $white;
                }
                .requests_list{
                    .list{
                        display: grid;
                        grid-template-columns: repeat(5, minmax(155px, 240px));
                        justify-items: stretch;
                        gap: 52px;
                        margin-top: 5rem;
                        width: 100%;
                        @include laptop {
                            gap: 15px;
                        }
                        @include mobile {
                            display: grid;
                            grid-template-columns: auto auto;
                            column-gap: 25px;
                            row-gap: 60px;
                            justify-items: stretch;
                        }
                        .item{
                            min-width: 155px;
                            max-width: 240px;
                            height: 176px;
                            border: 1px solid $white;
                            border-radius: $border_radius;
                            transition: all .2s;
                            @include mobile{
                                height: 200px;
                            }
                            &:hover{
                                border-color: rgba(255, 255, 255, 0.6);
                            }
                            .wrapper{
                                width: 100%;
                                height: 100%;
                                display: block;
                                margin: 0px;
                                padding: 0px;
                                position: relative;
                                border-radius: $border_radius;
                                text-decoration: none;
                                color: $white;
                                .icon{
                                    position: absolute;
                                    top: -30px;
                                    left: 16px;
                                    background-color: $white;
                                    width: 60px;
                                    aspect-ratio: 1/1;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    border-radius: $border_radius;
                                }
                                .text{
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    align-items: flex-start;
                                    padding: 45px 20px 16px 16px;
                                    gap: 10px;
                                    word-wrap: break-word;
                                    @include laptop {
                                        padding: 45px 16px 16px 16px;
                                    }
                                    .departement{
                                        font-family: 'Gotham-Medium';
                                        font-style: normal;
                                        font-size: 16px;
                                        line-height: 20px;
                                    }
                                    .title{
                                        font-family: 'Gotham-Light';
                                        font-style: normal;
                                        font-weight: 325;
                                        font-size: 20px;
                                        line-height: 24px;
                                        width: 100%;
                                        overflow: hidden;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 3;
                                        -webkit-box-orient: vertical;
                                        &:after{
                                            content: '';
                                            display: block;
                                            clear: both;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .see_more{
                        width: 100%;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        margin-top: 4rem;
                        @include mobile{
                            justify-content: flex-start;
                            margin-top: 2rem;
                        }
                        a{
                            text-decoration: none;
                            color: $white;
                            font-family: 'Gotham-Light';
                            font-style: normal;
                            font-weight: 325;
                            font-size: 16px;
                            line-height: 24px;
                            transition: all .3s;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 15px;
                            &:hover{
                                color: rgba(255,255,255,0.5);
                                svg{
                                    path{
                                        fill: rgba(255,255,255,0.5);
                                    }
                                }
                            }
                            svg{
                                width: 6px;
                                transition: all .3s;
                                path{
                                    fill: $white;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}