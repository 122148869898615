@import './variables.scss';
@import './mixins.scss';

.dashboard_warpper {
    .banner {
        background-image: url('../../public/assets/banners/dashboard_banner.jpg');
        background-size: 100%;
        background-repeat: no-repeat;
        height: calc(304px - 70px);
        position: relative;
        background-position-y: -304px;

        @include laptop {
            background-position-y: -150px;
        }

        @include mobile {
            background-size: 100%;
            height: 180px;
            background-position-y: -30px;
        }

        .filter_layer {
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            background-color: rgba(0,0,0,0.2);
        }

        .content_layer {
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;

            .search_title {
                max-width: 1440px;
                margin: 0 auto;
                padding: 0 1rem;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;
                gap: 50px;

                @include laptop {
                    max-width: 1200px;
                }

                @include mobile {
                    max-width: 100%;
                    align-items: start;
                    justify-content: space-between;
                    flex-direction: column-reverse;
                    height: calc(100% - 50px);
                }

                h1 {
                    @include heading-01;
                    font-weight: 325;
                    font-size: 36px;
                    color: $white;

                    @include mobile {
                        font-size: 26px;
                        line-height: 32px;
                        margin-top: 10px;
                    }
                }

                .breadcrum {
                    display: flex;
                    flex-direction: row;
                    gap: 8px;

                    span {
                        color: $white;
                        font-size: 13px;
                        line-height: 24px;
                        font-family: 'Gotham-Light';
                        cursor: pointer;

                        @include mobile {
                            font-size: 11px;
                            line-height: 16px;
                        }

                        a {
                            color: $white;
                            text-decoration: none;
                        }
                    }

                    .current {
                        font-family: 'Gotham-Book';
                        font-weight: 500;
                        cursor: default;
                    }
                }
            }
        }
    }

    .content_wrapper {
        max-width: 1440px;
        margin: 0 auto;
        padding: 0 1rem;
        position: relative;
        z-index: 30;
        display: grid;
        grid-template-columns: repeat(3, minmax(248px, 448px));
        column-gap: 32px;
        row-gap: 32px;
        margin-top: -130px;
        grid-template-areas:
            ". . warning" "top1 top2 top3" "main main main" "last last last";

        @include laptop {
            max-width: 1200px;
            padding: 1rem;
            column-gap: 15px;
            row-gap: 15px;
        }

        @include mobile {
            grid-template-columns: 100%;
            grid-template-areas: unset;
            column-gap: 12px;
            row-gap: 12px;
        }

        &.hotel {
            grid-template-columns: repeat(2, minmax(248px, 688px));
            grid-template-areas:
                ". warning" "top1 top2" "top3 .";
            justify-content: flex-end;
            margin-bottom: 12rem;

            @include mobile {
                grid-template-columns: 100%;
                grid-template-areas: unset;
                column-gap: 12px;
                row-gap: 12px;
            }

            .dashboard_card {
                &:nth-child(1) {
                    max-width: 448px;
                    justify-self: end;
                }

                &:nth-child(2) {
                    .card_content {
                        .error {
                            @include mobile {
                                height: 80px !important;
                            }
                        }
                    }
                }

                &:nth-child(3) {
                    grid-area: top2;

                    .card_content {
                        .content {
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;
                            gap: 30px;
                            align-items: flex-end;

                            @include laptop {
                                align-items: flex-start;
                            }

                            @include mobile {
                                gap: 25px;
                                justify-content: flex-start;
                            }

                            .password_chart {
                                background-color: $grey-01;
                                padding: 14px 24px;
                                width: 176px;
                                height: 172px;
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                gap: 14px;

                                @include mobile {
                                    width: 140px;
                                    height: 132px;
                                    padding: 8px 12px;
                                    justify-content: flex-start;
                                    align-items: center;
                                    gap: 12px;
                                }

                                .chart {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    align-content: center;
                                    position: relative;

                                    @include mobile {
                                        align-items: flex-start;
                                        align-content: flex-start;
                                        width: 90px;
                                        height: 90px;
                                    }

                                    .donut {
                                        @include mobile {
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }

                                    .donut-ring {
                                        stroke: $grey-03;

                                        @include mobile {
                                            stroke-width: 6px;
                                        }
                                    }

                                    .donut-segment {
                                        animation: chart-fill 1s reverse ease-in;
                                        transform-origin: center;
                                        stroke: $secondary;

                                        @include mobile {
                                            stroke-width: 6px;
                                        }
                                    }

                                    .days {
                                        position: absolute;
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: flex-start;
                                        align-items: center;
                                        gap: 0px;
                                        cursor: default;

                                        @include mobile {
                                            width: 100%;
                                            height: 100%;
                                            justify-content: center;
                                            gap: 4px;
                                        }

                                        .days_left {
                                            font-family: 'Gotham-Book';
                                            font-weight: 325;
                                            font-size: 36px;
                                            line-height: 40px;
                                            color: $secondary;

                                            @include mobile {
                                                font-size: 22px;
                                                line-height: 24px;
                                            }
                                        }

                                        .days_total {
                                            font-family: 'Gotham-Light';
                                            font-weight: 325;
                                            font-size: 12px;
                                            line-height: 5px;
                                            color: $grey-06;

                                            @include mobile {
                                                font-size: 10px;
                                            }
                                        }
                                    }
                                }

                                .remaining {
                                    font-family: 'Gotham-Medium';
                                    text-transform: uppercase;
                                    font-weight: 500;
                                    font-size: 12px;
                                    line-height: 20px;
                                    color: $black;
                                    width: 100%;
                                    display: flex;
                                    justify-content: center;
                                    cursor: default;
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                    gap: 10px;
                                    white-space: nowrap;

                                    @include mobile {
                                        font-size: 11px;
                                        line-height: 14px;
                                        font-family: 'Gotham-Book';
                                        margin-left: -10px;
                                    }
                                }
                            }

                            .password_info {
                                display: flex;
                                flex-wrap: wrap;
                                flex-direction: row;
                                height: 100%;

                                @include laptop {
                                    flex-direction: column;
                                    flex-wrap: nowrap;
                                    justify-content: flex-start;
                                    align-items: flex-start;
                                    position: relative;
                                    gap: 12px;
                                }

                                @include mobile {
                                    flex-direction: column;
                                    flex-wrap: nowrap;
                                    justify-content: flex-start;
                                    height: 132px;
                                    gap: 12px;
                                }

                                .date_item {
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    gap: 2px;
                                    width: 35%;
                                    cursor: default;
                                    margin-bottom: 95px;

                                    @include laptop {
                                        margin-bottom: 0px;
                                        width: 100%;
                                    }

                                    @include mobile {
                                        margin-bottom: 0px;
                                        width: 100%;
                                    }

                                    &:first-child {
                                        width: 65%;
                                    }

                                    &:nth-child(2) {
                                        @include mobile {
                                            margin-bottom: 5px;
                                        }
                                    }

                                    .date {
                                        font-family: 'Gotham-Book';
                                        font-weight: 325;
                                        font-size: 24px;
                                        line-height: 28px;

                                        @include mobile {
                                            font-size: 20px;
                                            line-height: 22px;
                                        }
                                    }

                                    .date_label {
                                        font-family: 'Gotham-Light';
                                        font-weight: 325;
                                        font-size: 16px;
                                        line-height: 20px;

                                        @include mobile {
                                            font-size: 14px;
                                            line-height: 18px;
                                        }
                                    }

                                    &.last span {
                                        color: $grey-04;
                                    }

                                    &.due .date {
                                        color: $secondary
                                    }
                                }

                                .reset {
                                    text-transform: uppercase;
                                    text-decoration: none;
                                    font-family: 'Gotham-Book';
                                    font-weight: 500;
                                    font-size: 12px;
                                    line-height: 16px;
                                    color: $secondary;
                                    width: -moz-fit-content;
                                    width: fit-content;
                                    position: relative;

                                    @include laptop {
                                        position: absolute;
                                        bottom: 0px;
                                        left: 0px;
                                    }

                                    &::after {
                                        content: "";
                                        height: 1px;
                                        width: 0%;
                                        display: block;
                                        position: absolute;
                                        background-color: $secondary;
                                        transition: all .2s ease-in;
                                        margin-top: .1rem;

                                        @include laptop {
                                            position: relative;
                                        }
                                    }

                                    &:hover {
                                        &::after {
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .dashboard_card {
            background-color: $white;
            border-radius: 3px;
            display: flex;
            border: 1px solid $grey-02;
            padding: 27px 32px 24px 32px;
            height: auto;

            @include laptop {
                padding: 20px 20px 20px 20px;
                height: auto;
            }

            @include mobile {
                grid-area: unset !important;
                height: auto;
                padding: 16px;
            }

            &.error {
                height: 160px !important;
            }

            .card_content {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 9px;
                width: 100%;

                &.error {
                    justify-content: space-between;

                    .content {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        width: 100%;
                        height: auto;
                        gap: 10px;

                        @include mobile {
                            height: 80px !important;
                        }

                        .icon {
                            width: 28px;
                            aspect-ratio: 1/1;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            svg {
                                width: 18px;

                                path {
                                    fill: $secondary;
                                }
                            }
                        }

                        .error_text {
                            font-family: 'Gotham-Book';
                            color: $secondary;
                            font-weight: 500;
                            font-size: 24px;
                            line-height: 28px;
                            font-style: normal;
                            width: 100%;

                            @include laptop {
                                font-size: 18px;
                                line-height: 20px;
                            }

                            @include mobile {
                                width: 100%;
                                font-size: 18px;
                                line-height: 20px;
                                font-family: 'Gotham-Light';
                            }
                        }
                    }
                }

                .title {
                    display: flex;
                    align-items: center;
                    align-content: center;
                    justify-content: space-between;

                    .title_name {
                        font-family: 'Gotham-Book';
                        font-weight: 325;
                        font-size: 24px;
                        line-height: 28px;
                        color: $grey-06;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 18px;
                        height: 100%;

                        @include laptop {
                            font-size: 20px;
                            line-height: 22px;
                            gap: 5px;
                        }

                        @include mobile {
                            font-size: 20px;
                            line-height: 22px;
                            justify-content: space-between;
                            width: 100%;
                        }

                        .total {
                            font-family: 'Gotham-Book';
                            font-style: normal;
                            font-weight: 325;
                            font-size: 24px;
                            line-height: 28px;
                            color: $secondary;

                            @include laptop {
                                font-size: 20px;
                                line-height: 22px;
                            }

                            @include mobile {
                                font-size: 20px;
                                line-height: 22px;
                            }
                        }
                    }

                    .title_link {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        height: 100%;

                        a {
                            font-family: 'Gotham-Book';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 16px;
                            text-transform: uppercase;
                            color: $secondary;
                            text-decoration: none;

                            @include laptop {
                                font-size: 11px;
                                line-height: 12px;
                            }

                            &:after {
                                content: '';
                                display: block;
                                width: 0;
                                height: 1px;
                                background: $secondary;
                                transition: width .3s;
                            }

                            &:hover {
                                &:after {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }

                .body {
                    display: flex;
                    flex-direction: column;
                    gap: 12px;

                    &.error {
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: flex-end;
                        gap: 4px;
                        height: 100%;

                        .content {
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: flex-start;
                            width: 100%;
                            height: auto;
                            gap: 10px;

                            .icon {
                                width: 28px;
                                aspect-ratio: 1/1;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                svg {
                                    width: 18px;

                                    path {
                                        fill: $secondary;
                                    }
                                }
                            }

                            .error_text {
                                font-family: 'Gotham-Light';
                                font-style: normal;
                                font-weight: 325;
                                font-size: 16px;
                                line-height: 22px;
                                color: $grey-06;
                                text-align: left;

                                @include laptop {
                                    font-size: 14px;
                                    line-height: 18px;
                                }
                            }
                        }
                    }
                }
            }

            &:nth-child(1) {
                grid-area: warning;
                background-color: rgba(255,255,255,0.7);
                padding: 7px 22px;
                display: flex;
                align-items: center;
                align-content: center;
                justify-content: flex-start;
                gap: 19px;
                height: unset;

                @include laptop {
                    padding: 13px 13px;
                    gap: 10px;
                }

                @include mobile {
                    padding: 7px 13px;
                    gap: 10px;
                    margin-bottom: 40px;
                }

                .logout_link {
                    font-family: 'Gotham-Book';
                    font-weight: 500;
                    width: -moz-fit-content;
                    width: fit-content;
                    position: relative;
                    cursor: pointer;

                    &::after {
                        content: "";
                        height: 1px;
                        width: 0%;
                        display: block;
                        position: absolute;
                        bottom: 0px;
                        left: 0px;
                        background-color: $black;
                        transition: all .2s ease-in;
                    }

                    &:hover {
                        &::after {
                            width: 100%;
                        }
                    }
                }

                .warning_icon {
                    svg {
                        width: 24px;
                        aspect-ratio: 1/1;

                        @include mobile {
                            width: 18px;
                        }

                        @include laptop {
                            width: 18px;
                        }
                    }
                }

                span {
                    font-family: 'Gotham-Light';
                    font-weight: 325;
                    font-size: 14px;
                    line-height: 18px;

                    @include laptop {
                        font-size: 13px;
                        line-height: 16px;
                    }

                    @include mobile {
                        font-size: 12px;
                        line-height: 12px;
                    }

                    strong {
                        font-family: 'Gotham-Book';
                    }
                }
            }

            &:nth-child(2) {
                grid-area: top1;
                padding: 24px 24px 22px 40px;

                @include laptop {
                    padding: 22px 22px 22px 25px;
                }

                @include mobile {
                    padding: 17px 16px;
                }

                .card_content {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    gap: 20px;

                    @include mobile {
                        justify-content: flex-start;
                        gap: 10px;
                    }

                    .header {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        align-content: center;
                        gap: 23px;

                        @include mobile {
                            gap: 15px;
                        }

                        .profile_avatar {
                            width: 72px;
                            height: 72px;
                            border-radius: 50%;
                            border: 1px solid $grey-03;
                            overflow: hidden;

                            @include laptop {
                                width: 60px;
                                height: 60px;
                            }

                            @include mobile {
                                width: 55px;
                                height: 55px;
                            }

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        .user_name {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-around;
                            gap: 6px;
                            width: calc(100% - 100px);

                            .name {
                                font-family: 'Gotham-Book';
                                color: $secondary;
                                font-weight: 500;
                                font-size: 24px;
                                line-height: 28px;
                                font-style: normal;
                                width: 100%;

                                @include laptop {
                                    font-size: 18px;
                                    line-height: 20px;
                                }

                                @include mobile {
                                    width: 100%;
                                    font-size: 18px;
                                    line-height: 20px;
                                    font-family: 'Gotham-Light';
                                }
                            }

                            .email {
                                font-family: 'Gotham-Light';
                                font-style: normal;
                                font-weight: 325;
                                font-size: 16px;
                                line-height: 20px;
                                color: $grey-06;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: block;
                                width: 100%;

                                @include laptop {
                                    font-size: 14px;
                                    line-height: 16px;
                                }

                                @include mobile {
                                    width: 100%;
                                    font-size: 14px;
                                    line-height: 16px;
                                }
                            }
                        }
                    }

                    .body {
                        display: flex;
                        flex-direction: column;
                        gap: 12px;

                        &.error {
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: flex-end;
                            height: 100%;
                            gap: 4px;

                            .content {
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-start;
                                align-items: flex-start;
                                width: 100%;
                                gap: 10px;

                                .icon {
                                    width: 28px;
                                    aspect-ratio: 1/1;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;

                                    svg {
                                        width: 18px;

                                        path {
                                            fill: $secondary;
                                        }
                                    }
                                }

                                .error_text {
                                    font-family: 'Gotham-Light';
                                    font-style: normal;
                                    font-weight: 325;
                                    font-size: 16px;
                                    line-height: 22px;
                                    color: $grey-06;
                                    text-align: left;

                                    @include laptop {
                                        font-size: 14px;
                                        line-height: 18px;
                                    }
                                }
                            }
                        }

                        .profile_item {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            align-items: stretch;
                            gap: 3px;

                            .title {
                                font-family: 'Gotham-Book';
                                font-weight: 325;
                                font-size: 16px;
                                line-height: 26px;
                                color: $secondary;
                                text-transform: uppercase;

                                @include laptop {
                                    font-size: 14px;
                                    line-height: 18px;
                                }

                                @include mobile {
                                    font-size: 14px;
                                    line-height: 18px;
                                    font-family: 'Gotham-Light';
                                }
                            }

                            .value {
                                font-family: 'Gotham-Book';
                                font-weight: lighter;
                                font-size: 18px;
                                line-height: 22px;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                overflow: hidden;
                                color: $black;

                                &.undefined {
                                    font-family: 'Gotham-Light';
                                    font-weight: 325;
                                    font-size: 16px;
                                    line-height: 20px;
                                    color: $grey-06;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    $color: $grey-06;
                                }

                                @include laptop {
                                    font-size: 16px;
                                    line-height: 20px;
                                }

                                @include mobile {
                                    font-size: 14px;
                                    line-height: 18px;
                                    font-family: 'Gotham-Light';
                                }
                            }
                        }
                    }
                }
            }

            &:nth-child(3) {
                grid-area: top2;

                .card_content {
                    &.error {
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: flex-start;
                        height: 100%;

                        .content {
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: flex-start;
                            width: 100%;
                            gap: 10px;

                            .icon {
                                width: 28px;
                                aspect-ratio: 1/1;
                                display: flex;
                                justify-content: center;
                                align-items: center;

                                svg {
                                    width: 18px;

                                    path {
                                        fill: $secondary;
                                    }
                                }
                            }

                            .error_text {
                                font-family: 'Gotham-Light';
                                font-style: normal;
                                font-weight: 325;
                                font-size: 16px;
                                line-height: 22px;
                                color: $grey-06;
                                text-align: left;
                            }
                        }
                    }

                    .content {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        gap: 33px;
                        align-items: flex-end;

                        @include laptop {
                            gap: 15px;
                        }

                        @include mobile {
                            gap: 25px;
                            justify-content: flex-start;
                        }

                        .password_chart {
                            background-color: $grey-01;
                            padding: 14px 24px;
                            width: 176px;
                            height: 172px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            gap: 14px;

                            @include laptop {
                                padding: 8px 18px;
                            }

                            @include mobile {
                                width: 140px;
                                height: 132px;
                                padding: 8px 12px;
                                justify-content: flex-start;
                                align-items: center;
                                gap: 12px;
                            }

                            .chart {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                align-content: center;
                                position: relative;

                                @include mobile {
                                    align-items: flex-start;
                                    align-content: flex-start;
                                    width: 90px;
                                    height: 90px;
                                }

                                .donut {
                                    @include mobile {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }

                                .donut-ring {
                                    stroke: $grey-03;

                                    @include mobile {
                                        stroke-width: 6px;
                                    }
                                }

                                .donut-segment {
                                    animation: chart-fill 1s reverse ease-in;
                                    transform-origin: center;
                                    stroke: $secondary;

                                    @include mobile {
                                        stroke-width: 6px;
                                    }
                                }

                                .days {
                                    position: absolute;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: flex-start;
                                    align-items: center;
                                    gap: 4px;
                                    cursor: default;

                                    @include mobile {
                                        width: 100%;
                                        height: 100%;
                                        justify-content: center;
                                        gap: 4px;
                                    }

                                    .days_left {
                                        font-family: 'Gotham-Book';
                                        font-weight: 325;
                                        font-size: 36px;
                                        line-height: 40px;
                                        color: $secondary;

                                        @include laptop {
                                            font-size: 28px;
                                            line-height: 32px;
                                        }

                                        @include mobile {
                                            font-size: 22px;
                                            line-height: 24px;
                                        }
                                    }

                                    .days_total {
                                        font-family: 'Gotham-Light';
                                        font-weight: 325;
                                        font-size: 12px;
                                        line-height: 5px;
                                        color: $grey-06;

                                        @include mobile {
                                            font-size: 10px;
                                        }
                                    }
                                }
                            }

                            .remaining {
                                font-family: 'Gotham-Medium';
                                text-transform: uppercase;
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 20px;
                                color: $black;
                                width: 100%;
                                cursor: default;
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                gap: 10px;
                                white-space: nowrap;

                                @include laptop {
                                    font-size: 12px;
                                    line-height: 16px;
                                    white-space: nowrap;
                                }

                                @include mobile {
                                    font-size: 11px;
                                    line-height: 14px;
                                    font-family: 'Gotham-Book';
                                    margin-left: -10px;
                                }
                            }
                        }

                        .password_info {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            height: 100%;

                            .date_item {
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                gap: 2px;
                                cursor: default;

                                .date {
                                    font-family: 'Gotham-Book';
                                    font-weight: 325;
                                    font-size: 24px;
                                    line-height: 28px;

                                    @include laptop {
                                        font-size: 18px;
                                        line-height: 22px;
                                    }

                                    @include mobile {
                                        font-size: 20px;
                                        line-height: 22px;
                                    }
                                }

                                .date_label {
                                    font-family: 'Gotham-Light';
                                    font-weight: 325;
                                    font-size: 16px;
                                    line-height: 20px;

                                    @include mobile {
                                        font-size: 14px;
                                        line-height: 18px;
                                    }
                                }

                                &.last span {
                                    color: $grey-04;
                                }

                                &.due .date {
                                    color: $secondary
                                }
                            }

                            .reset {
                                text-transform: uppercase;
                                text-decoration: none;
                                font-family: 'Gotham-Book';
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 16px;
                                color: $secondary;
                                width: -moz-fit-content;
                                width: fit-content;
                                position: relative;

                                @include laptop {
                                    font-size: 11px;
                                    line-height: 14px;
                                }

                                &::after {
                                    content: "";
                                    height: 1px;
                                    width: 0%;
                                    display: block;
                                    position: absolute;
                                    background-color: $secondary;
                                    transition: all .2s ease-in;
                                    margin-top: .1rem;
                                }

                                &:hover {
                                    &::after {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &:nth-child(4) {
                grid-area: top3;
                height: auto;

                @include mobile {
                    height: auto;
                }

                .error {
                    height: 100px;
                }

                .card_content {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    /*gap: 20px;*/

                    @include mobile {
                        justify-content: flex-start;
                        gap: 10px;
                    }

                    hr {
                        margin: 0;
                    }

                    .header {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        align-content: center;
                        /*gap: 23px;

                        @include mobile {
                            gap: 15px;
                        }*/


                        .title {
                            font-family: 'Gotham-Book';
                            color: $secondary;
                            font-weight: 500;
                            font-size: 28px;
                            line-height: 28px;
                            font-style: normal;
                            width: 100%;

                            @include laptop {
                                font-size: 18px;
                                line-height: 20px;
                            }

                            @include mobile {
                                width: 100%;
                                font-size: 18px;
                                line-height: 20px;
                                font-family: 'Gotham-Light';
                            }
                        }
                    }

                    .benefits {
                        display: flex;
                        flex-direction: column;
                        gap: 12px;
                        margin-top: 12px;

                        &.error {
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: flex-end;
                            height: 100%;
                            gap: 4px;

                            .content {
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-start;
                                align-items: flex-start;
                                width: 100%;
                                gap: 10px;

                                .error_text {
                                    font-family: 'Gotham-Light';
                                    font-style: normal;
                                    font-weight: 325;
                                    font-size: 16px;
                                    line-height: 22px;
                                    color: $grey-06;
                                    text-align: left;

                                    @include laptop {
                                        font-size: 14px;
                                        line-height: 18px;
                                    }
                                }
                            }
                        }

                        .benefit_item {
                            font-family: 'Gotham-Medium';
                            text-transform: uppercase;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 20px;
                            color: $black;
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            cursor: default;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            white-space: nowrap;

                            @include mobile {
                                font-size: 11px;
                                line-height: 14px;
                                font-family: 'Gotham-Book';
                                margin-left: -10px;
                            }

                            a {
                                color: $black;
                                text-decoration: none;
                            }

                            a:hover {
                                cursor: pointer;
                            }
                        }
                    }
                }
            }

            &:nth-child(6) {
                grid-area: last;

                .error {
                    height: 100px;
                }

                .content {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    gap: 33px;
                    align-items: flex-start;
                    width: 100%;

                    @include mobile {
                        flex-direction: column;
                        gap: 10px;
                    }

                    .absence_info {
                        background-color: $grey-01;
                        padding: 23px 20px;
                        width: 176px;
                        height: 172px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        gap: 22px;

                        @include mobile {
                            width: 100%;
                            height: auto;
                            padding: 8px 14px;
                            gap: 10px;
                            flex-direction: row;
                            justify-content: space-between;
                        }

                        .info_item {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            gap: 5px;

                            .days {
                                font-family: 'Gotham-Book';
                                font-weight: 325;
                                font-size: 24px;
                                line-height: 28px;
                                color: $secondary;

                                @include laptop {
                                    font-size: 20px;
                                    line-height: 22px;
                                }

                                @include mobile {
                                    font-size: 20px;
                                    line-height: 22px;
                                }
                            }

                            .label {
                                font-family: 'Gotham-Light';
                                font-weight: 325;
                                font-size: 16px;
                                line-height: 20px;
                                color: $black;

                                @include laptop {
                                    font-size: 14px;
                                    line-height: 18px;
                                }

                                @include mobile {
                                    font-size: 14px;
                                    line-height: 18px;
                                }
                            }
                        }
                    }

                    .absence_list {
                        width: calc(100% - 176px);
                        height: 172px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        @include mobile {
                            width: 100%;
                            height: auto;
                        }

                        .list_header {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            width: 100%;

                            @include mobile {
                                padding: 8px 0px 13px 0px;
                            }

                            .title {
                                font-family: 'Gotham-Light';
                                font-weight: 325;
                                font-size: 16px;
                                line-height: 20px;
                                color: $secondary;
                                text-transform: uppercase;

                                @include mobile {
                                    font-size: 14px;
                                    line-height: 18px;
                                }
                            }

                            .link {
                                text-transform: uppercase;
                                text-decoration: none;
                                font-family: 'Gotham-Book';
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 16px;
                                color: $secondary;
                                width: -moz-fit-content;
                                width: fit-content;
                                position: relative;

                                &::after {
                                    content: "";
                                    height: 1px;
                                    width: 0%;
                                    display: block;
                                    position: absolute;
                                    background-color: $secondary;
                                    transition: all .2s ease-in;
                                    margin-top: .1rem;
                                }

                                &:hover {
                                    &::after {
                                        width: 100%;
                                    }
                                }
                            }
                        }

                        .list_content {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            gap: 4px;
                            width: 100%;
                            overflow-y: auto;
                            margin-top: 7px;

                            @include mobile {
                                height: 142px;
                            }

                            .no_absences {
                                font-family: 'Gotham-Light';
                                font-weight: 325;
                                font-size: 14px;
                                line-height: 16px;
                            }

                            .list_item {
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                background-color: $grey-01;
                                padding: 13px 17px;
                                align-items: center;
                                align-content: center;

                                .date {
                                    font-family: 'Gotham-Light';
                                    font-weight: 325;
                                    font-size: 16px;
                                    line-height: 20px;
                                    color: $grey-06;

                                    @include mobile {
                                        font-size: 14px;
                                        line-height: 18px;
                                    }
                                }

                                .status {
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;
                                    width: 120px;

                                    @include mobile {
                                        justify-content: flex-end;
                                    }

                                    span {
                                        white-space: nowrap;
                                        font-weight: 325;
                                        font-size: 12px;
                                        line-height: 16px;
                                        font-family: 'Gotham-Light';

                                        &.approved {
                                            color: $success-02;
                                        }

                                        &.pending {
                                            color: $warning-02;
                                        }

                                        &.rejected {
                                            color: $error-02;
                                        }
                                    }
                                }
                            }
                        }

                        .list_footer {
                            padding-top: 13px;

                            .link {
                                text-transform: uppercase;
                                text-decoration: none;
                                font-family: 'Gotham-Book';
                                font-weight: 500;
                                font-size: 12px;
                                line-height: 16px;
                                color: $secondary;
                                width: -moz-fit-content;
                                width: fit-content;
                                position: relative;
                            }
                        }
                    }
                }
            }
        }
    }
}

@keyframes chart-fill {
    to {
        stroke-dasharray: 0 100;
    }
}
