@import './variables.scss';
@import './mixins.scss';

.skeleton{
  display: flex;
  align-content: center;
  gap: 10px;
}

.skeleton_line{
  border-radius: 3px;
  overflow: hidden;
  position: relative;
  &::before{
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to right, transparent 0%, rgba(0, 0, 0, 0.3) 20%, transparent 40%, transparent 100%);
    background-repeat: no-repeat;
    background-size: 450px 400px;
    animation: shimmer 1.25s linear infinite;
  }
}
.skeleton_gap{
  overflow: hidden;
  position: relative;
  background-color: transparent;
}
.skeleton_circle{
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  aspect-ratio: 1/1;
  &::before{
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to right, transparent 0%, rgba(0, 0, 0, 0.3) 20%, transparent 40%, transparent 100%);
    background-repeat: no-repeat;
    background-size: 450px 400px;
    animation: shimmer 1.25s linear infinite;
  }
}

@keyframes shimmer {
    0%{
      background-position: -450px 0;
    }
    100%{
      background-position: 450px 0;
    }
  }