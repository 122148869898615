@import './variables.scss';
@import './mixins.scss';

.search_box {
    width: 100%;
    background-color: #fff;
    height: 132px;
    border-radius: $border_radius;
    border: 1px solid $grey-02;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    padding: 50px 240px 40px 240px;
    @include laptop {
        padding: 50px 120px 40px 120px;
    }
    @include mobile {
        padding: 50px 20px 40px 20px;
    }
    form {
        width: 100%;
        position: relative;
        .input_group {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: .25rem;
            position: relative;
            width: 100%;
            .fluent_label {
                position: absolute;
                left: 0;
                top: -28px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                align-content: center;
                gap: 10px;
                height: 28px;
                width: 100%;
                .title {
                    font-family: 'Gotham-Book';
                    text-transform: uppercase;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    letter-spacing: 0.2px;
                    color: $grey-05;
                    width: fit-content;
                    @include mobile {
                        font-size: 11px;
                        line-height: 14px;
                    }
                }
                .info {
                    width: 24px;
                    aspect-ratio: 1/1;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    align-content: center;
                    &:hover {
                        background-color: $secondary-01;
                        cursor: pointer;
                    }
                    svg {
                        width: 14px;
                        aspect-ratio: 1/1;
                        path {
                            fill: $secondary;
                        }
                    }
                }
            }
            .fluent_input {
                width: 100%;
                height: 44px;
                padding: 13px 50px 13px 24px;
                border: 1px solid $grey-04;
                font-weight: 325;
                color: $grey-05;
                border-radius: 3px;
                @include text-01;
                @include mobile {
                    font-size: 13px;
                }
                @include laptop{
                    font-size: 14px;
                }
                &:focus, &:active {
                    outline: none;
                    border-color: $black;
                }
                &:hover {
                    outline: none;
                    border-color: $black;
                }
            }
            .input_icon {
                right: 10px;
                top: 50%;
                width: 34px;
                position: absolute;
                aspect-ratio: 1/1;
                display: flex;
                justify-content: center;
                align-items: center;
                align-content: center;
                transition: all .3s ease-in-out;
                border-radius: 50%;
                transform: translateY(-50%);
                &:hover {
                    background-color: $secondary-01;
                    cursor: pointer;
                }
                .icon {
                    width: 34px;
                    aspect-ratio: 1/1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    svg {
                        width: 20px;
                        aspect-ratio: 1/1;
                        path {
                            fill: $secondary
                        }
                    }
                }
            }
        }
        .search_list {
            display: none;
            &.active {
                z-index: 20;
                display: block;
                position: absolute;
                top: 50px;
                background-color: $white;
                width: 100%;
                border-radius: $border_radius;
                border: 1px solid $grey-02;
                box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.15);
                padding: 20px 0px 0px 0px;
                max-height: 386px;
                height: fit-content;
                overflow-y: scroll;
            }
            .search_wraper {
                .no_result {
                    padding: 0 20px;
                    font-family: 'Gotham-Book';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 20px;
                    color: $grey-05;
                    font-style: italic;
                }
                .search_item {
                    .section_results {
                        list-style: none;
                        padding: 0;
                        margin: 0;
                        display: flex;
                        flex-direction: column;
                        gap: .10rem;
                        margin-bottom: .75rem;
                        .section_title {
                            margin: 52px 0 0 0;
                            font-family: 'Gotham-Light';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 20px;
                            line-height: 20px;
                            color: $grey-06;
                            padding: 0px 20px;
                            margin-bottom: .75rem;
                            text-transform: capitalize;
                            &:first-child {
                                margin-top: 0px;
                            }
                        }
                        .result_item {
                            width: 100%;
                            &:hover {
                                background-color: $grey-01;
                                cursor: pointer;
                            }
                            .link {
                                display: flex;
                                justify-content: flex-start;
                                gap: .75rem;
                                align-items: center;
                                align-content: center;
                                width: 100%;
                                padding: 8px 20px;
                                text-decoration: none;
                                .title {
                                    @include text-01;
                                    font-size: 16px;
                                    font-weight: 325;
                                    color: $grey-06;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    .highlight {
                                        font-weight: 500;
                                        font-family: 'Gotham-Medium';
                                    }
                                    p {
                                        margin: 0;
                                        color: $grey-04;
                                        font-size: 14px;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        overflow: hidden;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.results_warpper{
    margin-top: 41px;
    .info_wrapper{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 8px;
        width: 100%;
        .info_icon{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 18px;
            height: 18px;
            aspect-ratio: 1/1;
            svg{
                path{
                    fill: $grey-06;
                }
            }
        }
        .info_title{
            font-family: 'Gotham-Light';
            font-style: normal;
            font-weight: 325;
            font-size: 16px;
            line-height: 20px;
            color: $grey-06;
            width: 100%;
            b{
                font-family: 'Gotham-Medium';
                font-weight: 100;
            }
        }
    }
    .results{
        width: 100%;
        max-height: 552px;
        height: fit-content;
        position: relative;
        border-radius: 3px;
        margin-top: 21px;
        .header{
            width: 100%;
            height: 48px;
            background-color: $grey-06;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 14px 40px;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            box-shadow: 0px 1px 8px rgba(0,0,0,0.4);
            z-index: 10;
            position: relative;
            div{
                overflow: hidden;
                .title{
                    font-family: 'Gotham-Medium';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    line-height: 20px;
                    color: $white;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 90%;
                    display: block;
                    @include laptop {
                        font-size: 14px;
                    }
                }
            }
            .subfolder{
                width: 30%;
            }
            .region{
                width: 10%;
            }
            .department{
                width: 30%;
            }
            .link{
                width: 20%;
            }
            .hotel{
                width: 10%;
            }
        }
        .no_results{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            align-content: center;
            gap: 10px;
            padding: 35px 20px;
            background-color: $white;
            .no_results_icon{
                width: 20px;
                height: 20px;
                aspect-ratio: 1/1;
                display: flex;
                justify-content: center;
                align-items: center;
                svg{
                    width: 100%;
                    height: 100%;
                    path{
                        fill: $secondary;
                    }
                }
            }
            .no_results_text{
                font-family: 'Gotham-Light';
                font-style: italic;
                font-weight: 300;
                font-size: 16px;
                line-height: 20px;
                color: $grey-06;
            }
        }
        .body{
            overflow-y: scroll;
            width: 100%;
            height: 100%;
            max-height: calc(552px - 48px);
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
            scrollbar-width: none;
            -ms-overflow-style: none;
            -ms-overflow-style: -ms-autohiding-scrollbar;
            &::-webkit-scrollbar {
                display: none;
            }
            .table_row{
                width: 100%;
                max-width: 100%;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 14px 40px;
                border-bottom: 1px solid $grey-02;
                background-color: $white;
                div{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    align-content: center;
                    gap: 8px;
                    .title{
                        font-family: 'Gotham-Light';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 20px;
                        color: $grey-06;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        width: 90%;
                        display: block;
                    }
                    .new_link{
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        align-content: center;
                        gap: 8px;
                        .divider{
                            height: 100%;
                            width: 2px;
                            background-color: $grey-06;
                        }
                    }
                    a{
                        text-decoration: none;
                        font-family: 'Gotham-Medium';
                        font-style: normal;
                        font-weight: 100;
                        font-size: 12px;
                        line-height: 16px;
                        color: $secondary;
                        text-transform: uppercase;
                        position: relative;
                        width: fit-content;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        width: 90%;
                        display: block;
                        &::after{
                            content: '';
                            position: absolute;
                            bottom: -2px;
                            display: block;
                            width: 0;
                            height: 1px;
                            background: $secondary;
                            transition: width .3s;
                        }
                        &:hover::after{
                            width: 100%;
                            transition: width .3s;
                        }
                    }
                }
                .subfolder{
                    width: 30%;
                }
                .region{
                    width: 10%;
                }
                .department{
                    width: 30%;
                }
                .hotel{
                    width: 10%;
                    a{
                        text-transform: lowercase !important;
                        font-family: 'Gotham-Medium';
                        font-style: normal;
                        font-weight: 100;
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
                .link{
                    width: 20%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 12px;
                    .info_icon{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 18px;
                        height: 18px;
                        aspect-ratio: 1/1;
                    }
                }
            }
        }
    }
}