@import './variables.scss';
@import './mixins.scss';

header{
    width: 100%;
    height: 80px;
    background-color: $white;
    box-shadow: 0px 1.6px 3px rgba(0, 0, 0, 0.13);
    position: fixed;
    top: 0;
    z-index: 99;
    @include mobile {
        height: 70px;
    }
    .wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 1440px;
        margin: 0 auto;
        padding: 0 1rem;
        height: 100%;
        @include laptop{
            max-width: 1200px;
            padding: 0px 0px 0rem 2rem;
        }
        @include mobile {
            max-width: 100%;
            margin: 0;
            padding: 0;
        }
        .open_menu{
            display: none;
            @include mobile {
                display: block;
                margin-left: 20px;
                .menu{
                    cursor: pointer;
                    width: 27px;
                    height: 20px;
                    -moz-user-select: none;
                    -webkit-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .line {
                        width: 100%;
                        height: 1px;
                        background-color: $grey-06;
                        transition: all 0.3s ease;
                        &:nth-child(2){
                            width: 50%;
                        }
                    }
                    &.active {
                        .line:nth-child(1){
                            transform: translateY(9px) rotate(45deg);
                        }
                        .line:nth-child(2){
                            visibility: hidden;
                            opacity: 0;
                        }
                        .line:nth-child(3){
                            transform: translateY(-9px) rotate(-45deg);
                        }
                    }
                }
            }
        }
        .logo{
            height: 100%;
            display: flex;
            width: fit-content;
            justify-content: center;
            align-items: center;
            @include mobile {
                width: 140px;
                margin-left: 15px;
                a{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    width: fit-content;
                    justify-content: center;
                    align-items: center;
                    svg{
                        width: 98%;
                        height: auto;
                    }
                }
            }
        }
        nav{
            height: 100%;
            width: 700px;
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
            @include mobile{
                opacity: 0;
                z-index: -10;
                visibility: hidden;
                height: 0%;
                width: 0%;
                transition: width .3s ease-in-out;
                box-shadow: 2px 8px 8px rgba(0,0,0,0.1);
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                position: fixed;
                top: 70px;
                background-color: $white;
                left: 0;
                transform: translate(0, 0);
                .nav__menu{
                    .nav__item{
                        width: 0%;
                        transition: width .3s ease-in-out;
                    }
                }
                &.active{
                    width: 90vw;
                    height: calc(100vh - 70px);
                    z-index: 99;
                    visibility: visible;
                    opacity: 1;
                    @include mobile{
                        position: fixed;
                    }
                    .nav__menu{
                        flex-direction: column;
                        height: 100%;
                        max-height: 100%;
                        overflow-y: scroll;
                        width: 100%;
                        .nav__item{
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            justify-content: flex-start;
                            height: fit-content;
                            padding: 15px 0px 15px 20px;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            transition: width .3s ease-in-out;
                            .nav__title, .nav__link{
                                display: flex;
                                justify-content: space-between;
                                align-content: flex-start;
                                align-items: flex-start;
                                flex-direction: column;
                                gap: 1rem;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                height: fit-content;
                            }
                        }
                    }
                    .mobile_tools{
                        height: 30%;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        padding: 20px 20px 25px 20px;
                        gap: 25px;
                        position: absolute;
                        bottom: 16px;
                        .tool{
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            justify-content: flex-start;
                            align-items: center;
                            align-content: center;
                            gap: 10px;
                            cursor: pointer;
                            text-decoration: none;
                            svg{
                                width: 20px;
                                height: 20px;
                            }
                            span{
                                text-transform: uppercase;
                                font-family: 'Gotham-Book';
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 16px;
                                color: $secondary;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }   
                    }
                }
            }
            .mobile_tools{
                display: none;
            }
            .nav__menu{
                display: flex;
                flex-direction: row;
                align-items: stretch;
                list-style: none;
                gap: 1rem;
                margin: 0;
                padding: 0;
                height: 100%;
                @include mobile {
                    gap: 0.2rem;
                }
                
                .nav__item{
                    color: $primary;
                    width: 128px;
                    text-align: center;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    cursor: pointer;
                    @include laptop {
                        width: 110px;
                    }
                    &.home{
                        display: none;
                        @include mobile {
                            display: flex;
                        }
                    }
                    .nav__title, .nav__link{
                        color: inherit;
                        text-decoration: none;
                        text-transform: uppercase;
                        font-family: 'Gotham-Light';
                        font-weight: 325;
                        font-size: 14px;
                        line-height: 16px;
                        text-align: center;
                        height: 100%;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        align-content: center;
                        justify-content: center;
                        .nav__submenu{
                            display: none;
                        }
                    }
                    &::after{
                        content: "";
                        height: 4px;
                        width: 0px;
                        display: block;
                        position: absolute;
                        bottom: 0;
                        background-color: $secondary;
                        transition: all .2s ease-in;
                        @include mobile {
                            top: 0;
                            right: 0;
                            width: 4px;
                            height: 0px;
                        }
                    }
                    &:hover, &.open{
                        .nav__title, .nav__link{
                            color: $secondary;
                        }
                        &::after{
                            width: 128px;
                            @include laptop {
                                width: 110px;
                            }
                            @include mobile {
                                height: 45px;
                                width: 4px;
                            }
                        }
                    }
                    &:hover, &.open{
                        .nav__title{
                            .nav__submenu{
                                display: grid;
                                justify-items: stretch;
                                grid-template-columns: auto auto;
                                grid-template-rows: repeat(9, auto);
                                grid-auto-flow: column;
                                position: absolute;
                                top: 80px;
                                left: 0px;
                                background-color: $white;
                                box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.1);
                                width: fit-content;
                                height: unset !important;
                                &.departments{
                                    left: unset !important;
                                    @include laptop {
                                        grid-template-rows: repeat(9, auto);
                                        .subitem{
                                            width: 343px;
                                        }
                                    }
                                }
                                &.tools{
                                    left: unset !important;
                                    @include laptop {
                                        width: 100vw !important;
                                        grid-template-rows: repeat(9, auto);
                                        left: 5%;
                                        transform: translate(-9%, 0%);
                                    }
                                }
                                @include mobile {
                                    z-index: 20;
                                    grid-template-columns: auto;
                                    grid-auto-flow: dense;
                                    position: relative;
                                    top: 0;
                                    right: 0;
                                    max-height: 220px;
                                    overflow-y: scroll;
                                    margin-bottom: -20px;
                                    width: 100%;
                                    max-width: 100%;
                                }
                                &.fitcolum{
                                    .subitem{
                                        max-width: 325px;
                                        min-width: 170px;
                                        width: -moz-available;
                                        @include laptop {
                                            width: 100%;
                                        }
                                    }
                                }
                                a{
                                    justify-content: flex-start !important;
                                }
                                .subitem{
                                    width: 404px;
                                    display: flex;
                                    flex-wrap: nowrap;
                                    align-items: center;
                                    align-content: center;
                                    justify-content: space-between;
                                    gap: 1rem;
                                    height: 44px;
                                    padding: 12px 16px;
                                    text-decoration: none;
                                    @include laptop {
                                        width: 343px;
                                    }
                                    .subitem_title{
                                        color: $grey-06;
                                        text-transform: none;
                                        font-family: 'Gotham-Light';
                                        font-style: normal;
                                        font-weight: 325;
                                        font-size: 16px;
                                        line-height: 24px;
                                        text-align: left;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        &.lowercase{
                                            text-transform: lowercase !important;
                                        }
                                    }
                                    .subitem__submenu{
                                        display: flex;
                                        justify-content: flex-start;
                                        align-items: center;
                                        gap: 8px;
                                        color: $grey-06;
                                        a, span{
                                            @include mobile {
                                                width: fit-content;
                                            }
                                        }
                                        a{
                                            text-decoration: none;
                                            color: $secondary;
                                            position: relative;
                                            width: fit-content;
                                            font-family: 'Gotham-Medium';
                                            font-weight: 100;
                                            font-size: 12px;
                                            line-height: 16px;
                                            &::after{
                                                content: "";
                                                height: 1px;
                                                width: 0px;
                                                display: block;
                                                position: absolute;
                                                bottom: -4px;
                                                background-color: $white;
                                                transition: all .2s ease-in;
                                            }
                                            &:hover{
                                                &::after{
                                                    width: 100%;
                                                }
                                            }
                                        }
                                        span{
                                            color: $grey-06;
                                        }
                                    }
                                    svg{
                                        width: 6px;
                                    }

                                    @include mobile {
                                        justify-content: space-between;
                                        width: 100%;
                                        gap: 0px;
                                        padding: 3px 8px 3px 8px;
                                        span{
                                            font-size: 15px;
                                            width: 100%;
                                        }
                                    }
                                    &:hover{
                                        background-color: $secondary;
                                        span{
                                            color: $white;
                                        }
                                        .subitem__submenu a{
                                            color: $white;
                                        }
                                        svg{
                                            path{
                                                fill: $white;
                                            }
                                        }
                                    }
                                }
                                &.departments{
                                    .subitem{
                                        @include mobile {
                                            width: 98%;
                                            span{
                                                width: 55% !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &:first-child{
                        &.open{
                            .nav__title{
                                .nav__submenu{
                                    grid-template-columns: auto;
                                    .subitem{
                                        width: 100%;
                                        @include mobile {
                                            justify-content: space-between !important;
                                            padding-right: 20px;
                                            span{
                                                width: 100% !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .tools{
            height: 100%;
            ul{
                list-style: none;
                display: flex;
                align-items: stretch;
                align-content: center;
                justify-content: center;
                font-size: 1rem;
                margin: 0;
                padding: 0;
                height: 100%;
                position: relative;
                li{
                    width: 48px;
                    aspect-ratio: 1/1;
                    display: flex;
                    align-items: center;
                    align-content: center;
                    justify-content: center;
                    cursor: pointer;
                    @include laptop {
                        width: 45px;
                    }
                    @include mobile {
                        width: 38px;
                    }
                    svg{
                        width: 24px;
                        fill: $secondary;
                        @include mobile {
                            width: 22px;
                        }
                        path{
                            fill: $secondary;
                        }
                    }
                }
                .tool_help, .tool_chat{
                    @include laptop {
                        width: 38px;
                    }
                    @include mobile {
                        display: none !important;
                    }
                }
                .tool_help{
                    width: 48px;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    .help_menu{
                        visibility: hidden;
                        opacity: 0;
                        position: absolute;
                        background-color: $white;
                        box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.1);
                        bottom: -88px;
                        left: 0;
                        width: 300px;
                        @include laptop {
                            left: unset;
                            right: 0;
                        }
                        &.short{
                            bottom: -44px;
                        }
                        .menu{
                            width: 100%;
                            .menu_item{
                                width: 100%;
                                display: flex;
                                flex-wrap: nowrap;
                                align-items: center;
                                align-content: center;
                                justify-content: flex-start;
                                gap: 1rem;
                                height: 44px;
                                padding: 12px 16px;
                                text-decoration: none;
                                span{
                                    color: $grey-06;
                                    font-family: "Gotham-Light";
                                    font-style: normal;
                                    font-weight: 325;
                                    font-size: 16px;
                                    line-height: 24px;
                                    text-align: left;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    overflow: hidden;
                                }
                                svg{
                                    width: 6px;
                                }
                                &:hover{
                                    background-color: $secondary;
                                    span{
                                        color: $white;
                                    }
                                    svg{
                                        path{
                                            fill: $white;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &:hover{
                        .help_menu{
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                }
                .tool_search{
                    background-color: $primary;
                    &.active{
                        background-color: $secondary;
                    }
                    svg path{
                        fill: $white;
                    }
                }
                .tool_profile{
                    div, a {
                        height: 100%;
                        width: 100%;
                        img{
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                        }
                    }
                }
                .tool_loader{
                    background-color: $grey-02;
                }
            }
        }
    }
}
.search_bar{
    display: none;
    position: absolute;
    top: 80px;
    z-index: 999;
    right: 0;
    max-width: 1440px;
    min-width: 1440px;
    margin-right: -1rem;
    padding: 0 1rem;
    @include laptop {
        margin: 0px;
        padding: 0px 0px 0rem 2rem;
        width: 100vw;
        min-width: 1200px;
        max-width: 1200px;
    }
    @media only screen and (min-width: 500px) and (max-width: 1200px) {
        margin: 0px;
        padding: 0px 0px 0rem 0rem;
        width: 100vw;
        min-width: 100vw;
        max-width: 100vw;
    }
    @include mobile {
        top: 70px;
        min-width: 100vw;
        max-width: 100vw;
    }
    &.active{
        display: block;
        .search_wraper{
            width: 100%;
            background-color: rgba(0,0,0,0.3);
            padding: 20px 0px;
            @include laptop {
                padding: 20px 20px;
            }
            form{
                width: 100%;
                position: relative;
                display: flex;
                justify-content: center;
                .input_group{
                    width: 100%;
                    max-width: 1168px;
                    min-width: 1168px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    gap: .25rem;
                    position: relative;
                    width: 100%;
                    @include laptop {
                        min-width: 100%;
                        max-width: 100%;
                    }
                    .fluent_input{
                        width: 100%;
                        height: 44px;
                        padding: 13px 50px 13px 24px;
                        border: 1px solid $grey-04;
                        font-weight: 325;
                        color: $grey-05;
                        @include text-01;
                        @include mobile {
                            font-size: 12px;
                        }
                        &:focus, &:active{
                            outline: none;
                            border-color: $black;
                        }
                        &:hover {
                            outline: none;
                            border-color: $black;
                        }
                    }
                    .input_icon{
                        right: 10px;
                        top: 50%;
                        width: 34px;
                        position: absolute;
                        aspect-ratio: 1/1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        align-content: center;
                        transition: all .3s ease-in-out;
                        border-radius: 50%;
                        transform: translateY(-50%);
                        &:hover{
                            background-color: $secondary-01;
                            cursor: pointer;
                        }
                        .icon{
                            width: 34px;
                            aspect-ratio: 1/1;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            align-content: center;
                            svg{
                                width: 20px;
                                aspect-ratio: 1/1;
                                path{
                                    fill: $secondary
                                } 
                            }
                        }
                    }
                }
            }
        }
    }
}
.tasks_menu{
    width: 328px;
    @include mobile{
        width: 288px;
    }
    .menu_wraper{
        @include mobile{
            margin: 0px !important;
            max-width: 100% !important;
            width: 100% !important;
        }
        .menu{
            width: 100%;
        }
    }
}
.profile_menu, .tasks_menu{
    display: none;
    position: absolute;
    top: 83px;
    z-index: 999;
    right: 0;
    @include mobile{
        top: 75px;
        right: 10px;
    }
    &.active{
        display: block;
        @include mobile{
            display: flex;
            justify-content: center;
        }
    }
    .menu_wraper{
        max-width: 1440px;
        margin: 0 auto;
        padding: 0;
        display: flex;
        justify-content: flex-end;
        @include laptop{
            max-width: 1200px;
        }
        .menu{
            background-color: $white;
            border-radius: $border_radius;
            box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.1);
            .menu_header{
                border-top-left-radius: $border_radius;
                border-top-right-radius: $border_radius;
                background-color: $primary;
                color: $white;
                padding: 16px 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                align-content: center;
                gap: 2rem;
                span{
                    color: inherit;
                    text-transform: uppercase;
                    font-weight: 325;
                    font-size: 12px;
                    line-height: 16px;
                    font-family: "Gotham-Light";
                    user-select: none;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    align-content: center;
                    gap: 1rem;
                    white-space: nowrap;
                    svg{
                        width: 18px;
                        aspect-ratio: 1/1;
                        path{
                            fill: $white;
                        }
                    }
                }
                .logout, .login{
                    text-decoration: none;
                    color: $white;
                    background-color: $primary;
                    text-transform: uppercase;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                    font-family: "Gotham-Book";
                    width: fit-content;
                    position: relative;
                    cursor: pointer;
                    white-space: nowrap;
                    &::after{
                        content: "";
                        height: 1px;
                        width: 0%;
                        display: block;
                        position: absolute;
                        background-color: $white;
                        transition: all .2s ease-in;
                        margin-top: .2rem;

                    }
                    &:hover{
                        &::after{
                            width: 100%;
                        }
                    }
                }
            }
            .menu_content{
                padding: 16px 20px;
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                gap: 16px;
                .profile_avatar{
                    width: 72px;
                    height: 72px;
                    border-radius: 50%;
                    border: 1px solid $grey-03;
                    overflow: hidden;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .user_info{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    gap: 0.25rem;
                    .name{
                        font-family: 'Gotham-Medium';
                        color: $grey-06;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 20px;
                        font-style: normal;
                    }
                    .email{
                        font-family: 'Gotham-Light';
                        font-style: normal;
                        font-weight: 325;
                        font-size: 14px;
                        line-height: 16px;
                        color: $grey-06;
                        @include mobile {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: block;
                            width: 230px;
                        }
                    }
                    a{
                        text-decoration: none;
                    }
                    .profile{
                        text-decoration: none;
                        text-transform: uppercase;
                        color: $secondary;
                        font-family: 'Gotham-Book';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 16px;
                        width: fit-content;
                        position: relative;
                        cursor: pointer;
                        &::after{
                            content: "";
                            height: 1px;
                            width: 0%;
                            display: block;
                            position: absolute;
                            background-color: $secondary;
                            transition: all .2s ease-in;
                            margin-top: .1rem;
    
                        }
                        &:hover{
                            &::after{
                                width: 100%;
                            }
                        }
                    }
                }
                .tasks{
                    width: 100%;
                    .task{
                        width: 100%;
                        display: flex;
                        justify-content: flex-start;
                        gap: 8px;
                        align-items: flex-start;
                        .task_icon{
                            width: 24px;
                            aspect-ratio: 1/1;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            svg{
                                width: 16px;
                                path{
                                    fill: $secondary;
                                }
                            }
                        }
                        .task_info{
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            gap: 8px;
                            margin-top: 8px;
                            .task_title{
                                font-family: 'Gotham-Medium';
                                font-style: normal;
                                font-weight: 100;
                                font-size: 16px;
                                line-height: 16px;
                                color: $secondary;
                            }
                            .task_description{
                                font-family: 'Gotham-Light';
                                font-style: normal;
                                font-weight: 325;
                                font-size: 15px;
                                line-height: 20px;
                                color: $grey-06;
                            }
                            .link{
                                margin-top: 11px;
                                font-family: 'Gotham-Light';
                                font-style: normal;
                                font-weight: 325;
                                font-size: 16px;
                                line-height: 24px;
                                color: $grey-06;
                                transition: all .2s ease-in-out;
                                cursor: pointer;
                                text-decoration: none;
                                svg{
                                    transition: all .2s ease-in-out;
                                    margin-left: 8px;
                                    width: 6px;
                                }
                                &:hover{
                                    color: $secondary;
                                    svg{
                                        margin-left: 18px;
                                    }
                                }
                            }
                        }
                        
                    }
                    .no_tasks{
                        @include text-02;
                        font-style: normal;
                        font-weight: 325;
                        font-size: 14px;
                        line-height: 16px;
                        color: $grey-06;
                    }
                }
            }
        }
    }
}