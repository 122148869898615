// Colors //

$primary: #53565A;
$secondary: #C00D0D;

$white: #FFFFFF;
$grey-01: #F4F4F4;
$grey-02: #DFDFDF;
$grey-03: #BDBDBD;
$grey-04: #99999C;
$grey-05: #76787A;
$grey-06: #53565A;
$grey-07: #333333;
$black: #000000;

$secondary-01: #F4E4E3;
$secondary-02: #D48B89;
$secondary-03: #BC4843;
$secondary-04: #C00D0D; // Base Color //
$secondary-05: #B20B0B;
$secondary-06: #930808;
$secondary-07: #710505;

$success-01: #81D673;
$success-02: #37AB00; // Base Color //
$success-03: #1A7C0A;

$warning-01: #FFC586;
$warning-02: #FF9900; // Base Color //
$warning-03: #D3740E;

$error-01: #FD7F88;
$error-02: #E30000; // Base Color //
$error-03: #AA000D;

//Elements //

$border_radius: 3px;

// Devices //

$mobile-max: 480px;

$tablet-min: 481px;
$tablet-max: 768px;

$laptop-min: 767px;
$laptop-max: 1599px;

// Breakpoints //

$breakpoints: (
    mobile: 0px,
    tablet: 481px,
    laptop: 767px,
    desktop: 1600px
);

// Fonts //

@font-face {
    font-family: "Gotham-Book";
    src: local("GothamBook"), url("../../public/assets/fonts/Gotham/Gotham-Book.otf") format("opentype");
}

@font-face {
    font-family: "Gotham-Medium";
    src: local("GothamMedium"), url("../../public/assets/fonts/Gotham/Gotham-Medium.otf") format("opentype");
}

@font-face {
    font-family: "Gotham-Light";
    src: local("GothamLight"), url("../../public/assets/fonts/Gotham/Gotham-Light.otf") format("opentype");
}
