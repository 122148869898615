@import './variables.scss';
@import './mixins.scss';

footer{
    margin: 6rem 0 0 0;
    width: 100%;
    height: 332px;
    background-color: $primary;
    @include mobile {
        height: 632px;
        margin: 3rem 0 0 0;
    }
    @include tablet {
        height: 632px;
        margin: 3rem 0 0 0;
    }
    .wrapper {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        max-width: 1440px;
        margin: 0 auto;
        padding: 35px 1rem 120px 1rem;
        height: 100%;
        gap: 3rem;
        @include mobile {
            padding: 0 .75rem;
            margin: 0;
            max-width: 100%;
            justify-content: center;
            gap: 1rem;
        }
        @include tablet {
            padding: 0 .75rem;
            margin: 0;
            max-width: 100%;
            justify-content: center;
            gap: 1rem;
        }        @include laptop{
            max-width: 1200px;
        }
        .footer {
            @include mobile {
                width: 100%;
                height: 80%;
            }
            img {
                width: 100%;
                height: auto;
                object-fit: contain;
                @include mobile {
                    width: 100%;
                    height: 100%;
                }
                @include tablet {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .copyright {
            font-family: 'Gotham-Book';
            font-weight: 325;
            font-size: 14px;
            line-height: 16px;
            color: $white;
            @include mobile {
                height: 20%;
                display: flex;
                justify-content: center;
                align-items: center;
                align-content: center;
            }
            @include tablet {
                height: 20%;
                display: flex;
                justify-content: center;
                align-items: center;
                align-content: center;
            }
        }
    }
}