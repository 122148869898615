@import './variables.scss';
@import './mixins.scss';

.frequently_used_area{
    width: 100%;
    .frequents {
        width: 100%;
        background-color: #fff;
        height: 280px;
        border-radius: $border_radius;
        border: 1px solid $grey-02;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        padding:80px 88px 55px 88px;
        gap: 152px;
        @include laptop {
            padding: 60px 60px 30px 60px;
            gap: 50px;
        }
        @include mobile {
            flex-direction: column;
            height: auto;
            gap: 50px;
            padding: 18px 16px;
        }
        .area_title{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 5px;
            max-width: 30%;
            @include laptop {
                max-width: 300px;
            }
            @include mobile{
                gap: 12px;
                max-width: 100%;
            }
            .title{
                font-family: 'Gotham-Book';
                font-style: normal;
                font-weight: 325;
                font-size: 36px;
                line-height: 40px;
                color: $grey-06;
                @include laptop {
                    font-size: 30px;
                    line-height: 36px;
                }
                @include mobile{
                    font-size: 22px;
                    line-height: 24px;
                }
            }
            .sub_title{
                font-family: 'Gotham-Light';
                font-style: normal;
                font-weight: 325;
                font-size: 16px;
                line-height: 20px;
                color: $grey-06;
                @include mobile{
                    font-size: 14px;
                    line-height: 16px;
                }
            }
        }
        .frequent_tools{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 44px;
            width: 70%;
            @include mobile{
                flex-wrap: wrap;
                column-gap: 5%;
                row-gap: 50px;
                width: 100%;
                height: 100%;
            }
            .no_tools{
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                .title{
                    font-family: 'Gotham-Book';
                    font-style: normal;
                    font-weight: 325;
                    font-size: 30px;
                    line-height: 40px;
                    color: $grey-06;
                    @include mobile{
                        font-size: 18px;
                        line-height: 24px;
                    }
                }
                .subtitle{
                    font-family: 'Gotham-Light';
                    font-style: normal;
                    font-weight: 325;
                    font-size: 16px;
                    line-height: 20px;
                    color: $grey-06;
                    @include mobile{
                        font-size: 14px;
                        line-height: 16px;
                    }
                }
            }
            .tool{
                max-width: 240px;
                min-width: 150px;
                max-height: 176px;
                min-height: 156px;
                aspect-ratio: 3/2;
                border: 1px solid $grey-03;
                border-radius: $border_radius;
                transition: all .2s;
                @include mobile{
                    width: 47.5%;
                    height: 156px;
                }
                &:hover{
                    border-color: $grey-05;
                }
                .wrapper{
                    width: 100%;
                    height: 100%;
                    display: block;
                    margin: 0px;
                    padding: 0px;
                    position: relative;
                    border-radius: $border_radius;
                    text-decoration: none;
                    color: $grey-06;
                    .icon{
                        position: absolute;
                        top: -30px;
                        left: 16px;
                        background-color: $secondary;
                        width: 60px;
                        aspect-ratio: 1/1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: $border_radius;
                        svg{
                            path{
                                fill: $white;
                            }
                        }
                        @include mobile{
                            width: 52px;
                        }
                    }
                    .text{
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        padding: 55px 20px 16px 16px;
                        gap: 10px;
                        word-wrap: break-word;
                        text-transform: capitalize;
                        @include mobile{
                            padding: 35px 16px 16px 16px;
                            gap: 8px;
                        }
                        .departement{
                            font-family: 'Gotham-Medium';
                            font-style: normal;
                            font-size: 16px;
                            line-height: 20px;
                            @include mobile {
                                font-size: 12px;
                                line-height: 20px;
                            }
                        }
                        .title{
                            font-family: 'Gotham-Light';
                            font-style: normal;
                            font-weight: 325;
                            font-size: 20px;
                            width: 100%;
                            line-height: 24px; overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            &:after{
                                content: '';
                                display: block;
                                clear: both;
                            }
                            @include mobile {
                                font-size: 16px;
                                line-height: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.resources_area{
    margin-top: 84px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;
    @include mobile{
        margin-top: 24px;
        gap: 12px;
    }
    .area_title{
        width: 100%;
        .title{
            font-family: 'Gotham-Book';
            font-style: normal;
            font-weight: 325;
            font-size: 36px;
            line-height: 40px;
            color: $grey-06;
            @include laptop {
                font-size: 30px;
                line-height: 36px;
            }
            @include mobile{
                font-size: 20px;
                line-height: 36px;
            }
        }
    }
    .resources_toolbar{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        @include mobile{
            flex-direction: column;
            gap: 21px;
        }
        .search{
            width: 688px;
            height: 100%;
            @include laptop {
                width: 450px;
            }
            @include mobile{
                width: 100%;
            }
            .input_group{
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: .25rem;
                position: relative;
                width: 100%;
                border-radius: 3px;
                @include mobile{
                    min-width: 100%;
                    max-width: 100%;
                }
                .fluent_label{
                    left: 0;
                    top: -28px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    align-content: center;
                    gap: 10px;
                    height: 28px;
                    width: 100%;
                    .title{
                        font-family: 'Gotham-Book';
                        text-transform: uppercase;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 18px;
                        letter-spacing: 0.2px;
                        color: $grey-06;
                        width: fit-content;
                        @include mobile{
                            font-size: 11px;
                            line-height: 14px;
                        }
                    }
                }
                .fluent_input{
                    width: 100%;
                    height: 44px;
                    padding: 13px 50px 13px 24px;
                    border: 1px solid $grey-04;
                    font-weight: 325;
                    color: $grey-05;
                    border-radius: 3px;
                    @include text-01;
                    @include mobile{
                        font-size: 13px;
                    }
                    &:focus, &:active{
                        outline: none;
                        border-color: $black;
                    }
                    &:hover {
                        outline: none;
                        border-color: $black;
                    }
                }
                .input_icon{
                    right: 10px;
                    top: 90%;
                    width: 34px;
                    position: absolute;
                    aspect-ratio: 1/1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    transition: all .3s ease-in-out;
                    border-radius: 50%;
                    transform: translateY(-90%);
                    &:hover{
                        background-color: $secondary-01;
                        cursor: pointer;
                    }
                    .icon{
                        width: 34px;
                        aspect-ratio: 1/1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        align-content: center;
                        svg{
                            width: 20px;
                            aspect-ratio: 1/1;
                            path{
                                fill: $secondary
                            } 
                        }
                    }
                }
            }
        }
        .filters{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            gap: 32px;
            height: 100%;
            width: 400px;
            @include laptop{
                width: 300px;
            }
            @include mobile{
                justify-content: space-between;
                width: 100%;
            }
            .filter_sort{
                .filter_wrapper{
                    position: relative;
                    &.open{
                        .filter .drop_down{
                            svg{
                                transform: rotate(180deg);
                            }
                        }
                        .filter_menu{
                            visibility: visible;
                            opacity: 1;
                            height: 80px;
                            background-color: $white;
                            position: absolute;
                            z-index: 100;
                            width: 100%;
                            box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.15);
                            border-radius: 3px;
                            .filter_item{
                                width: 100%;
                                padding: 10px 16px;
                                border-bottom: 1px solid $grey-03;
                                font-family: 'Gotham-Light';
                                font-style: normal;
                                font-weight: 325;
                                font-size: 16px;
                                line-height: 20px;
                                color: $grey-06;
                                height: 40px;
                                &.active{
                                    color: $secondary;
                                }
                                &:last-child{
                                    border-bottom: none;
                                    border-bottom-left-radius: 3px;
                                    border-bottom-right-radius: 3px;
                                }
                                &:first-child{
                                    border-top-left-radius: 3px;
                                    border-top-right-radius: 3px;
                                }
                                &:hover{
                                    background-color: $grey-01;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                    .filter {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        align-content: center;
                        padding: 8px;
                        border: 1px solid $grey-04;
                        border-radius: 3px;
                        width: 210px;
                        background-color: $white;
                        cursor: pointer;
                        @include laptop {
                            width: 180px;
                        }
                        .label {
                            display: block;
                            text-align: left;
                            font-family: 'Gotham-Light';
                            font-style: normal;
                            font-weight: 325;
                            font-size: 16px;
                            color: $grey-06;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            width: 180px;
                            cursor: pointer;
                            @include laptop {
                                width: 150px;
                            }
                            @include mobile {
                                font-size: 15px;
                            }
                        }
                        .drop_down {
                            width: 20px;
                            height: 20px;
                            aspect-ratio: 1/1;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            align-content: center;
                            cursor: pointer;
                            svg{
                                transition: all .3s ease-in-out;
                            }
                        }
                    }
                    .filter_menu{
                        height: 0px;
                        visibility: hidden;
                        opacity: 0;
                    }
                }
            }
            .styles_toolbar {
                .wrapper {
                    width: 100%;
                    height: 42px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    align-content: center;
                    .icon {
                        width: 44px;
                        height: 44px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        align-content: center;
                        border-radius: 3px;
                        transition: all .3s ease-in-out;
                        &.list{
                            svg{
                                width: 18px;
                                height: 18px;
                            }
                        }
                        svg {
                            width: 15px;
                            height: 15px;
                            path {
                                fill: $grey-06;
                            }
                        }
                        &.active {
                            svg path {
                                fill: $secondary;
                            }
                            &:hover {
                                cursor: pointer;
                                svg path {
                                    fill: $secondary;
                                }
                            }
                        }
                        &:hover {
                            cursor: pointer;
                            svg path {
                                fill: $grey-04;
                            }
                        }
                        &:active, &:focus{
                            background-color: $grey-02;
                        }
                    }
                }
            }
        }
    }
    .tools_list_wrapper{
        width: 100%;
        transition: all .3s ease-in-out;
        @include mobile{
            margin-top: 9px;
        }
        .tools_list{
            width: 100%;
            transition: all .3s ease-in-out;
            &.list{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                .tool{
                    width: 100%;
                    height: 92px;
                    max-height: 92px;
                    background-color: $white;
                    padding: 16px 28px;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 52px;
                    border-bottom: 1px solid $grey-03;
                    text-decoration: none;
                    color: $grey-06;
                    cursor: pointer;
                    user-select: none;
                    @include laptop{
                        height: 110px;
                        max-height: 110px;
                        padding: 16px 16px;
                        gap: 30px;
                    }
                    @include mobile{
                        padding: 16px 16px;
                        gap: 16px;
                        height: 60px;
                    }
                    .tool_icon{
                        width: 15%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        @include mobile{
                            width: 84px;
                            min-width: 84px;
                            height: 28px;
                        }
                        &.small{
                            img{
                                width: 50px;
                                @include mobile{
                                    height: 35px;
                                    width: 35px;
                                    aspect-ratio: 1/1;
                                }
                            }
                            svg{
                                width: 30px;
                                path{
                                    fill: $secondary;
                                }
                            }
                        }
                        svg{
                            width: 128px !important;
                            height: 35px !important;
                            aspect-ratio: 1/1;
                        }
                        img{
                            width: 128px;
                            @include mobile{
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                    .tool_info{
                        width: 30%;
                        max-width: 357px;
                        @include laptop {
                            width: 20%;
                        }
                        a{
                            text-decoration: none;
                        }
                        .tool_title{
                            font-family: 'Gotham-Light';
                            font-style: normal;
                            font-weight: 325;
                            font-size: 16px;
                            line-height: 24px;
                            color: $grey-06;
                            text-transform: uppercase;
                            width: 60%;
                            display: block;
                            @include laptop{
                                font-size: 14px;
                                width: 100%;
                            }
                        }
                    }
                    .tool_description{
                        max-width: 700px;
                        width: 55%;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: flex-start;
                        gap: 8px;
                        @include laptop {
                            width: 65%;
                        }
                        span{
                            font-family: 'Gotham-Light';
                            font-style: normal;
                            font-weight: 325;
                            font-size: 14px;
                            line-height: 18px;
                        }
                        .request_link{
                            text-decoration: none;
                            color: $secondary;
                            font-family: 'Gotham-Book';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 16px;
                            text-transform: uppercase;
                            &:after {
                                content: '';
                                display: block;
                                margin-top: 2px;
                                width: 0;
                                height: 1px;
                                background: $secondary;
                                transition: width .3s;
                            }
                            &:hover:after {
                                width: 100%;
                                transition: width .3s;
                            }
                        }
                    }
                }
            }
            &.mosaic{
                display: grid;
                grid-template-columns: repeat(4, minmax(248px, 328px));
                justify-items: flex-start;
                column-gap: 32px;
                row-gap: 24px;
                @include laptop{
                    grid-template-columns: repeat(3, minmax(240px, 380px));
                }
                @include mobile{
                    width: 100%;
                    grid-template-columns: 47.5% 47.5%;
                    row-gap: 16px;
                    column-gap: 5%;
                }
                .tool{
                    max-width: 328px;
                    min-width: 240px;
                    width: 100%;
                    height: 80px;
                    background-color: $white;
                    border-radius: 3px;
                    border: 1px solid $grey-02;
                    padding: 16px;
                    text-decoration: none;
                    color: $grey-06;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 12px;
                    transition: all .3s ease-in-out;
                    cursor: pointer;
                    justify-self: flex-start;
                    @include laptop{
                        max-width: 380px;
                        height: 85px;
                        padding: 16px 16px;
                        gap: 16px;
                    }
                    @include mobile {
                        width: 100%;
                        height: 92px;
                        min-height: 92px;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        gap: 5px;
                    }
                    &:hover{
                        border: 1px solid $grey-03;
                    }
                    &.icon{
                        @include mobile {
                            padding: 10px;
                        }
                    }
                    .tool_info{
                        @include mobile{
                            display: flex;
                            width: 100%;
                            justify-content: center;
                            align-items: center;
                            height: 40px;
                        }
                        a{
                            text-decoration: none;
                        }
                        .tool_title{
                            font-family: 'Gotham-Light';
                            font-style: normal;
                            font-weight: 325;
                            font-size: 18px;
                            line-height: 24px;
                            text-transform: uppercase;
                            color: $grey-06;
                            @include mobile{
                                font-size: 14px;
                                line-height: 16px;
                                text-align: center;
                                width: 100%;
                            }
                        }
                    }
                    .tool_description{
                        display: none;
                    }
                    .tool_icon{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 50px;
                        height: 50px;
                        aspect-ratio: 1/1;
                        img{
                            max-height: 50px;
                            width: auto;
                            @include mobile{
                                width: 100%;
                            }
                        }
                        &.small{
                            svg{
                                width: 30px;
                                path{
                                    fill: $secondary;
                                }
                            }
                        }
                    }
                    &.image{
                        .tool_icon{
                            width: 100% !important;
                            max-height: 80px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            @include mobile{
                                width: 100%;
                                height: 100%;
                            }
                            svg{
                                width: 100% !important;
                                height: 55px !important;
                            }
                            img{
                                max-height: 50px;
                                width: auto;
                                max-width: 328px;
                                @include mobile{
                                    width: 100%;
                                }
                            }
                        }
                        .tool_info{
                            display: none;
                        }
                    }
                }
            }
        }
    }
}