@import './variables.scss';
@import './mixins.scss';

$height: 13px;
$width: 13px;

.main_error{
    min-height: 100vh;
    height: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0px !important;
    .error{
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        gap: 30px;
        height: 100vh;
        .wrapper{
            display: flex;
            flex-direction: column;
            gap: 31px;
            height: auto;
            .error_icon{
                width: 80px;
                height: 80px;
                aspect-ratio: 1/1;
                display: flex;
                justify-content: center;
                align-items: center;
                svg{
                    width: 100%;
                    height: 100%;
                    aspect-ratio: 1/1;
                    path{
                        fill: $grey-05;
                    }
                }
            }
            .title_wrapper{
                display: flex;
                flex-direction: column;
                gap: 12px;
                .title{
                    font-family: 'Gotham-Light';
                    font-style: normal;
                    font-weight: 325;
                    font-size: 36px;
                    line-height: 40px;
                    color: $grey-06;
                }
                .subtitle{
                    font-family: 'Gotham-Light';
                    font-style: normal;
                    font-weight: 325;
                    font-size: 16px;
                    line-height: 20px;
                    color: $grey-06;
                }
                .acordion_section{
                    .acordion_item{
                        .title{
                            font-family: 'Gotham-Light';
                            font-style: normal;
                            font-weight: 325;
                            font-size: 16px;
                            line-height: 20px;
                            color: $grey-06;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 15px;
                            transition: all 0.3s ease-in-out;
                            cursor: pointer;
                            user-select: none;
                            svg{
                                transition: all 0.3s ease-in-out;
                            }
                        }
                        .details{
                            margin: 20px 0px;
                            font-family: 'Gotham-Light';
                            font-style: normal;
                            font-weight: 325;
                            font-size: 16px;
                            line-height: 20px;
                            color: $grey-06;
                            opacity: 0;
                            visibility: hidden;
                            transition: all 0.3s ease-in-out;
                        }
                        &.open{
                            .title{
                                svg{
                                    transform: rotate(180deg);
                                }
                            }
                            .details{
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                    }
                }
            }
            .error_btn{
                height: fit-content;
                width: fit-content;
                padding: 12px 25px;
                border-radius: 3px;
                border: none;
                background-color: $secondary;
                &:hover{
                    background-color: $secondary-03;
                }
                a, span{
                    text-decoration: none;
                    text-transform: uppercase;
                    color: $white;
                    font-family: 'Gotham-Light';
                    font-style: normal;
                    font-weight: 325;
                    font-size: 12px;
                    line-height: 16px;
                    cursor: pointer;
                    user-select: none;
                }
            }
        }
    }
}