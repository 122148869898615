@import './variables.scss';
@import './mixins.scss';

.search_area {
    max-width: 1440px;
    margin: 0 auto;
    position: relative;
    z-index: 30;

    @include laptop {
        max-width: 1200px;
    }

    .search_box {
        width: 100%;
        background-color: #fff;
        height: 132px;
        margin: -3rem 0 0 0;
        border-radius: $border_radius;
        border: 1px solid $grey-02;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        padding: 50px 240px 40px 240px;

        @include mobile {
            padding: 50px 20px 40px 20px;
        }

        @include tablet {
            padding: 50px 30px 40px 30px;
        }

        @include laptop {
            padding: 50px 100px 40px 100px;
        }

    form {
        width: 100%;
        position: relative;

        .input_group {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: .25rem;
            position: relative;
            width: 100%;

            .fluent_label {
                position: absolute;
                left: 0;
                top: -28px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                align-content: center;
                gap: 10px;
                height: 28px;
                width: 100%;

                .title {
                    font-family: 'Gotham-Book';
                    text-transform: uppercase;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    letter-spacing: 0.2px;
                    color: $grey-05;
                    width: fit-content;

                    @include mobile {
                        font-size: 11px;
                        line-height: 14px;
                    }
                }

                .info {
                    width: 24px;
                    aspect-ratio: 1/1;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    align-content: center;

                    &:hover {
                        background-color: $secondary-01;
                        cursor: pointer;
                    }

                    svg {
                        width: 14px;
                        aspect-ratio: 1/1;

                        path {
                            fill: $secondary;
                        }
                    }
                }
            }

            .fluent_input {
                width: 100%;
                height: 44px;
                padding: 13px 50px 13px 24px;
                border: 1px solid $grey-04;
                font-weight: 325;
                color: $grey-05;
                border-radius: 3px;
                @include text-01;

                @include mobile {
                    font-size: 13px;
                }

                &:focus, &:active {
                    outline: none;
                    border-color: $black;
                }

                &:hover {
                    outline: none;
                    border-color: $black;
                }
            }

            .input_icon {
                right: 10px;
                top: 50%;
                width: 34px;
                position: absolute;
                aspect-ratio: 1/1;
                display: flex;
                justify-content: center;
                align-items: center;
                align-content: center;
                transition: all .3s ease-in-out;
                border-radius: 50%;
                transform: translateY(-50%);

                &:hover {
                    background-color: $secondary-01;
                    cursor: pointer;
                }

                .icon {
                    width: 34px;
                    aspect-ratio: 1/1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-content: center;

                    svg {
                        width: 20px;
                        aspect-ratio: 1/1;

                        path {
                            fill: $secondary
                        }
                    }
                }
            }
        }

        .search_list {
            display: none;

            &.active {
                z-index: 20;
                display: block;
                position: absolute;
                top: 50px;
                background-color: $white;
                width: 100%;
                border-radius: $border_radius;
                border: 1px solid $grey-02;
                box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.15);
                padding: 20px 0px;
                max-height: 516px;
                height: fit-content;
                overflow-y: scroll;
            }

            .search_wraper {
                .no_result {
                    padding: 0 20px;
                    font-family: 'Gotham-Book';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 20px;
                    color: $grey-05;
                    font-style: italic;
                }

                .search_item {
                    .section_results {
                        list-style: none;
                        padding: 0;
                        margin: 0;
                        display: flex;
                        flex-direction: column;
                        gap: .10rem;
                        margin-bottom: .75rem;

                        .section_title {
                            margin: 0;
                            font-family: 'Gotham-Light';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 20px;
                            line-height: 20px;
                            color: $grey-06;
                            padding: 0px 20px;
                            margin-bottom: .75rem;
                            text-transform: capitalize;
                        }
                            .no_results{
                                width: 100%;
                                padding: 10px 0px;
                                span{
                                    padding: 0px 20px;
                                    font-family: 'Gotham-Book';
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 20px;
                                    color: $grey-05;
                                    font-style: italic;
                                }
                            }
                        .result_item {
                            width: 100%;

                            &:hover {
                                background-color: $grey-01;
                                cursor: pointer;
                            }

                            .link {
                                display: flex;
                                justify-content: flex-start;
                                gap: .75rem;
                                align-items: center;
                                align-content: center;
                                width: 100%;
                                padding: 8px 20px;
                                text-decoration: none;

                                i {
                                    aspect-ratio: 1/1;
                                    display: flex;
                                    width: 28px;
                                    height: 28px;
                                    align-content: center;
                                    align-items: center;
                                    justify-content: center;
                                    overflow: inherit;
                                }

                                .icon {
                                    width: 30px;
                                    aspect-ratio: 1/1;
                                    color: $white;
                                    background-color: $secondary;
                                    display: flex;
                                    align-items: center;
                                    align-content: center;
                                    justify-content: center;
                                    font-family: 'Gotham-Light';
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 12px;
                                    line-height: 20px;
                                    border-radius: $border_radius;
                                }

                                .avatar {
                                    width: 35px;
                                    aspect-ratio: 1/1;
                                    border-radius: 50%;
                                    overflow: hidden;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                    }
                                }

                                .no_image {
                                    width: 35px;
                                    aspect-ratio: 1/1;
                                    border-radius: 50%;
                                    overflow: hidden;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    align-content: center;
                                    color: $white;
                                    font-family: 'Gotham-Light';
                                    font-style: normal;
                                    font-weight: 300;
                                    font-size: 16px;
                                    background-color: $grey-05;

                                    @include mobile {
                                        width: 28px;
                                        font-size: 12px
                                    }
                                }

                                .title {
                                    @include text-01;
                                    font-size: 16px;
                                    font-weight: 325;
                                    color: $grey-06;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    overflow: hidden;

                                    .highlight {
                                        font-weight: 500;
                                        font-family: 'Gotham-Medium';
                                    }

                                    p {
                                        margin: 0;
                                        color: $grey-04;
                                        font-size: 14px;
                                        text-overflow: ellipsis;
                                        white-space: nowrap;
                                        overflow: hidden;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.search_results {
    .title {
        h1 {
            padding: 0 !important;
            text-transform: none;
        }
    }

    .no_results {
        padding: 20px 0;
        font-family: 'Gotham-Book';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: $grey-05;
        font-style: italic;
    }

    .results_wraper {
        position: relative;
        .total_results{
            span{
                font-family: 'Gotham-Light';
                font-style: normal;
                font-weight: 325;
                font-size: 16px;
                line-height: 16px;
                color: $grey-06;
                padding: 22px 0px 32px 0px;

                @include mobile {
                    padding: 16px 0px;
                }
            }
        }
        .ms-FocusZone {
            margin: 30px 0px 20px 0px;

            @include mobile {
                margin: 5px 0px 10px 0px;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                width: 100%;
                gap: 45px;
            }

            .ms-Button {
                padding: 0px;
                margin-right: 54px;
                z-index: 10;

                @include mobile {
                    margin-right: 0px;
                }

                &:active {
                    background-color: transparent;
                }

                &:hover {
                    &::before {
                        background-color: $secondary;
                        width: 100%;
                    }

                    .ms-Pivot-text {
                        color: $secondary;
                    }
                }

                &.is-selected::before {
                    background-color: $secondary;
                    width: 100%;
                }

                &::before {
                    height: 4px;
                    left: 0;
                    right: 0;
                    width: 0%;
                    transition: width .2s ease-in-out;
                }

                .ms-Pivot-linkContent {
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    align-content: flex-end;
                }

                .ms-Pivot-text {
                    font-family: 'Gotham-Light';
                    font-style: normal;
                    font-weight: 325;
                    font-size: 16px;
                    line-height: 20px;
                    color: $grey-06;
                }
            }
        }

        .no_results {
            padding: 20px 0;
            font-family: 'Gotham-Book';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: $grey-05;
            font-style: italic;
        }

        .users {
            .styles_toolbar {
                width: 50%;
                margin: -70px 0px 20px 0px;
                height: 50px;
                position: absolute;
                z-index: 10;
                right: 0;
                    @include tablet {
                        display: none;
                    }
                @include mobile {
                    display: none;
                }

                .wrapper {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    align-content: center;
                    gap: 30px;

                    .icon {
                        width: 15px;
                        height: 15px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        align-content: center;

                        svg {
                            width: 100%;
                            height: 100%;

                            path {
                                fill: $grey-06;
                            }
                        }

                        &.active {
                            svg path {
                                fill: $secondary;
                            }

                            &:hover {
                                cursor: pointer;

                                svg path {
                                    fill: $secondary;
                                }
                            }
                        }

                        &:hover {
                            cursor: pointer;

                                svg path {
                                    fill: $grey-04;
                                }
                            }
                        }
                    }
                }
                .filters_wrapper{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    @include mobile {
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: flex-start;
                        gap: 16px;
                    }
                    @include tablet {
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: flex-start;
                        gap: 16px;
                    }
                    .filters_toolbar{
                        width: 100%;
                    }
                    .mobile_filters_description{
                        width: 100%;
                        .wrapper{
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: flex-start;
                            gap: 5px;
                            width: 90%;
                            .title{
                                font-family: 'Gotham-Medium';
                                font-style: normal;
                                font-weight: 500;
                                font-size: 13px;
                                line-height: 16px;
                                color: $grey-06;
                            }
                            .description{
                                font-family: 'Gotham-Light';
                                font-style: normal;
                                font-weight: 500;
                                font-size: 13px;
                                line-height: 16px;
                                color: $grey-06;
                            }
                        }
                    }
                    .filters_toolbar {
                        @include mobile {
                            margin-top: 16px;
                        }

                        .wrapper {
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;
                            gap: 24px;
                            @include laptop {
                                justify-content: flex-start;
                                flex-direction: column;
                                gap: 16px;
                            }
                            .filters {
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-start;
                                gap: 24px;
                                @include tablet {
                                    display: none;
                                    &.open {
                                        display: flex;
                                        flex-direction: column;
                                        position: fixed;
                                        top: 70px;
                                        left: 0;
                                        width: 50%;
                                        background-color: #FFFFFF;
                                        z-index: 99;
                                        height: calc(100vh - 70px);
                                        box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
                                        padding: 16px;
                                        gap: 16px;
                                    }
                                }
                                @include mobile {
                                    display: none;
                                    &.open {
                                        display: flex;
                                        flex-direction: column;
                                        position: fixed;
                                        top: 70px;
                                        left: 0;
                                        width: 85%;
                                        background-color: #FFFFFF;
                                        z-index: 99;
                                        height: calc(100vh - 70px);
                                        box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
                                        padding: 16px;
                                        gap: 16px;
                                    }
                                }

                                .filter_wrapper {
                                    position: relative;
                                    display: flex;
                                    flex-direction: column;
                                    gap: 10px;
                                    width: 100%;
                                    max-width: 210px;
                                    min-width: 100px;
                                    @include laptop {
                                        max-width: 230px !important;
                                    }
                                    @include mobile {
                                        max-width: 100% !important;
                                    }
                                    @include tablet {
                                        max-width: 100% !important;
                                    }
                                    &:last-child{
                                        .filter_menu{
                                            left: unset;
                                            right: 0;
                                        }
                                    }
                                    .filter {
                                        display: flex;
                                        flex-direction: row;
                                        justify-content: space-between;
                                        align-items: center;
                                        align-content: center;
                                        padding: 8px;
                                        border: 1px solid $grey-04;
                                        border-radius: 3px;
                                        width: 100%;
                                        background-color: $white;
                                        cursor: pointer;
                                        @include mobile {
                                            border: none;
                                            padding: 4px 0px;
                                        }
                                        @include tablet {
                                            border: none;
                                            padding: 4px 0px;
                                        }
                                        .label {
                                            display: block;
                                            text-align: left;
                                            font-family: 'Gotham-Light';
                                            font-style: normal;
                                            font-weight: 325;
                                            font-size: 16px;
                                            color: $grey-06;
                                            text-overflow: ellipsis;
                                            white-space: nowrap;
                                            overflow: hidden;
                                            width: 180px;
                                            cursor: pointer;
                                            @include mobile {
                                                font-size: 15px;
                                            }
                                            @include tablet {
                                                font-size: 15px;
                                            }
                                        }
                                        .drop_down {
                                            width: 20px;
                                            height: 20px;
                                            aspect-ratio: 1/1;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            align-content: center;
                                            cursor: pointer;
                                        }
                                    }

                                    .filter_menu {
                                        position: absolute;
                                        top: 48px;
                                        left: 0;
                                        width: 336px;
                                        background-color: $white;
                                        visibility: hidden;
                                        opacity: 0;
                                        box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1);
                                        border-radius: 2px;
                                        max-height: 488px;
                                        z-index: 99;
                                        @include mobile {
                                            position: unset;
                                            top: unset;
                                            left: unset;
                                            display: none;
                                            max-height: 300px;
                                            width: calc(100% + 16px);
                                            opacity: 1;
                                            visibility: visible;
                                            box-shadow: none;
                                            background-color: $grey-01;
                                        }
                                        @include tablet {
                                            position: unset;
                                            top: unset;
                                            left: unset;
                                            display: none;
                                            max-height: 300px;
                                            width: calc(100% + 16px);
                                            opacity: 1;
                                            visibility: visible;
                                            box-shadow: none;
                                            background-color: $grey-01;
                                        }
                                        .search_input {
                                            width: 100%;
                                            position: relative;
                                            padding: 13px 13px 0px 13px;
                                            @include tablet {
                                                padding: 7px 16px 0px 7px;
                                            }
                                            @include mobile {
                                                padding: 7px 16px 0px 7px;
                                            }
                                            input {
                                                width: 100%;
                                                border: 1px solid $grey-03;
                                                border-radius: 2px;
                                                color: $grey-06;
                                                padding: 6px 30px 6px 8px;
                                                font-family: 'Gotham-Light';
                                                font-style: normal;
                                                font-weight: 325;
                                                font-size: 16px;
                                                line-height: 20px;
                                                @include tablet {
                                                    font-size: 14px;
                                                }
                                                @include mobile {
                                                    font-size: 14px;
                                                }
                                                &:active, &:focus {
                                                    outline: none;
                                                    border: 1px solid $grey-04;
                                                }
                                            }

                                            .icon {
                                                position: absolute;
                                                right: 20px;
                                                top: 20px;
                                                width: 20px;
                                                height: 20px;
                                                display: flex;
                                                justify-content: center;
                                                align-items: center;
                                                align-content: center;
                                                @include tablet {
                                                    right: 25px;
                                                    top: 15px;
                                                }
                                                @include mobile {
                                                    right: 25px;
                                                    top: 15px;
                                                }

                                                svg {
                                                    width: 80%;

                                                    path {
                                                        fill: $secondary;
                                                    }
                                                }
                                            }
                                        }

                                        .options_wrapper {
                                            display: flex;
                                            flex-direction: row;
                                            justify-content: space-between;
                                            align-items: center;
                                            align-content: center;
                                            margin-top: 17px;
                                            padding: 0px 13px;
                                            @include tablet {
                                                padding: 0px 16px 0px 7px;
                                            }
                                            @include mobile {
                                                padding: 0px 16px 0px 7px;
                                            }

                                            .option {
                                                font-family: 'Gotham-Light';
                                                font-style: normal;
                                                font-weight: 500;
                                                font-size: 12px;
                                                line-height: 16px;
                                                color: $secondary;
                                                text-transform: uppercase;
                                                cursor: pointer;
                                                position: relative;
                                                @include tablet {
                                                    font-size: 11px;
                                                }
                                                @include mobile {
                                                    font-size: 11px;
                                                }

                                                &::after {
                                                    content: '';
                                                    position: absolute;
                                                    left: 0;
                                                    height: 1px;
                                                    width: 0px;
                                                    bottom: -3px;
                                                    transition: all .2s ease-in-out;
                                                }

                                                &:hover {
                                                    &::after {
                                                        width: 100%;
                                                        background-color: $secondary;
                                                    }
                                                }
                                            }
                                        }

                                        .list_wrapper {
                                            display: flex;
                                            flex-direction: column;
                                            margin: 12px 0px;
                                            max-height: 390px;
                                            overflow-y: scroll;
                                            @include mobile {
                                                height: 180px;
                                            }
                                            @include tablet {
                                                height: 200px;
                                            }
                                            .item {
                                                display: flex;
                                                justify-content: space-between;
                                                align-items: center;
                                                align-content: center;
                                                position: relative;

                                                &:hover {
                                                    background-color: $grey-01;
                                                }

                                                .wrapper {
                                                    border-bottom: 1px solid $grey-04;
                                                    padding: 10px 0px;
                                                    margin: 0px 13px;
                                                    height: 100%;
                                                    width: 100%;
                                                    display: flex;
                                                    justify-content: space-between;
                                                    align-content: center;
                                                    align-items: center;
                                                    @include mobile {
                                                        margin: 0px 16px 0px 7px;
                                                        border: none;
                                                        padding: 7px 0px;
                                                    }
                                                    @include tablet {
                                                        margin: 0px 16px 0px 7px;
                                                        border: none;
                                                        padding: 7px 0px;
                                                    }
                                                    @include laptop {
                                                        justify-content: flex-start;
                                                        align-items: flex-start;
                                                    }

                                                    .title {
                                                        font-family: 'Gotham-Light';
                                                        font-style: normal;
                                                        font-weight: 325;
                                                        font-size: 16px;
                                                        line-height: 20px;
                                                        color: $grey-06;
                                                        width: 80%;
                                                        @include mobile {
                                                            font-size: 14px;
                                                        }
                                                    }

                                                    input {
                                                        position: absolute;
                                                        opacity: 0;
                                                        cursor: pointer;
                                                        right: 13px;
                                                        z-index: 99;
                                                        width: 15px;
                                                        height: 15px;
                                                        top: 50%;
                                                        transform: translate(0%, -50%);
                                                        @include mobile {
                                                            right: 16px;
                                                        }
                                                        @include tablet {
                                                            right: 16px;
                                                        }
                                                    }

                                                    .checkmark {
                                                        position: absolute;
                                                        right: 13px;
                                                        height: 15px;
                                                        width: 15px;
                                                        top: 50%;
                                                        transform: translate(0%, -50%);
                                                        background-color: transparent;
                                                        border: 2px solid $secondary;
                                                        border-radius: 3px;
                                                        display: flex;
                                                        justify-content: center;
                                                        align-items: center;
                                                        align-content: center;
                                                        @include tablet {
                                                            right: 16px;
                                                        }
                                                        @include mobile {
                                                            right: 16px;
                                                        }

                                                        &::after {
                                                            content: "";
                                                            display: none;
                                                            width: 4px;
                                                            height: 8px;
                                                            margin-top: -3px;
                                                            border: solid white;
                                                            border-width: 0 1px 1px 0;
                                                            -webkit-transform: rotate(45deg);
                                                            -ms-transform: rotate(45deg);
                                                            transform: rotate(45deg);
                                                        }
                                                    }

                                                    input:checked ~ .checkmark {
                                                        background-color: $secondary;
                                                    }

                                                    input:checked ~ .checkmark::after {
                                                        display: block !important;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    &.open {
                                        .filter {
                                            .drop_down {
                                                transform: rotate(180deg);
                                            }
                                        }

                                        .filter_menu {
                                            visibility: visible;
                                            opacity: 1;
                                            @include tablet {
                                                display: block;
                                            }
                                            @include mobile {
                                                display: block;
                                            }
                                        }
                                    }
                                }

                                .mobile_buttons {
                                    position: absolute;
                                    bottom: 16px;
                                    width: 90%;

                                    .buttons_wrapper {
                                        flex-direction: row-reverse;
                                        justify-content: space-between;
                                        width: 100%;

                                        .btn_apply {
                                            padding: 8px 16px;
                                        }
                                    }
                                }
                            }

                            .mobile_filters_button {
                                button {
                                    color: $white;
                                    background-color: $secondary;
                                    border: none;
                                    border-radius: 3px;
                                    padding: 10px 16px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    align-content: center;
                                    gap: 10px;
                                    text-transform: uppercase;
                                    font-family: 'Gotham-Light';
                                    font-style: normal;
                                    font-weight: 325;
                                    font-size: 12px;
                                    line-height: 16px;
                                }
                            }

                            .buttons_wrapper {
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-start;
                                gap: 15px;

                                .btn_apply {
                                    padding: 9px 30px;
                                    border-radius: 3px;
                                    border: none;
                                    text-transform: uppercase;
                                    background-color: $grey-04;
                                    color: $grey-06;
                                    font-family: 'Gotham-Light';
                                    font-style: normal;
                                    font-weight: 325;
                                    font-size: 12px;
                                    line-height: 16px;
                                    cursor: default;

                                    &.active {
                                        background-color: $secondary;
                                        color: $white;
                                        cursor: pointer;
                                    }
                                }

                                .btn_clear {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    align-content: center;
                                    text-transform: uppercase;
                                    color: $secondary-02;
                                    font-family: 'Gotham-Book';
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 12px;
                                    line-height: 16px;
                                    cursor: default;
                                    white-space: nowrap;
                                    &.active {
                                        color: $secondary;
                                        cursor: pointer;
                                        position: relative;

                                        &::after {
                                            content: '';
                                            position: absolute;
                                            left: 0;
                                            height: 1px;
                                            width: 0px;
                                            bottom: 7px;
                                            transition: all .2s ease-in-out;
                                        }

                                        &:hover {
                                            &::after {
                                                width: 100%;
                                                background-color: $secondary;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .export_toolbar{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border-top: 1px solid $grey-03;
                    margin-top: 22px;
                    @include mobile {
                        border: none;
                        margin-top: 6px;
                    }
                    .total_results {
                        span{
                            font-family: 'Gotham-Light';
                            font-style: normal;
                            font-weight: 325;
                            font-size: 16px;
                            line-height: 16px;
                            color: $grey-06;
                            padding: 22px 0px 32px 0px;

                            @include mobile {
                                padding: 16px 0px;
                            }
                        }
                    }
                    .export_wrapper{
                        padding: 22px 0px 32px 0px;
                        .title{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            align-content: center;
                            text-transform: uppercase;
                            color: $secondary;
                            font-family: 'Gotham-Book';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 16px;
                            cursor: pointer;
                            position: relative;
                            span{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                gap: 10px;
                                svg{
                                    path{
                                        fill: $secondary;
                                    }
                                }
                            }
                            &::after {
                                content: '';
                                position: absolute;
                                left: 0;
                                height: 1px;
                                width: 0px;
                                bottom: -3px;
                                transition: all .2s ease-in-out;
                            }
                            &:hover {
                                &::after {
                                    width: 100%;
                                    background-color: $secondary;
                                }
                            }
                        }
                    }
                }

            .users_results {
                width: 100%;
                display: grid;
                column-gap: 32px;
                row-gap: 24px;
                justify-items: stretch;
                justify-content: flex-start;

                @include mobile {
                    justify-content: inherit;
                }
                    &.laptop{
                        display: none;
                        @include laptop {
                            display: grid;
                            .result_item{
                                .user_data{
                                    grid-template-columns: 52px repeat(3, minmax(48px, 300px))!important;
                                    width: 100%;
                                    .data_column{
                                        &:first-child{
                                            max-width: 48px !important;
                                            width: 100%;
                                        }
                                        &:nth-child(2){
                                            max-width: 300px !important;
                                            min-width: 100px !important;
                                            width: 100%;
                                        }
                                        &:nth-child(3){
                                            max-width: 220px !important;
                                            min-width: 100px !important;
                                            width: 100%;
                                        }
                                        &:nth-child(4){
                                            max-width: 277px !important;
                                            min-width: 100px !important;
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                            
                        }
                    }
                    &.desktop{
                        display: grid;
                        @include laptop {
                            display: none;
                        }
                    }
                &.mosaic {
                    grid-template-columns: repeat(3, minmax(422px, 768px));
                        @include tablet {
                            grid-template-columns: repeat(1, minmax(322px, 768px));
                        }
                    @include mobile {
                        grid-template-columns: repeat(1, minmax(322px, 768px));
                    }
                        @include laptop {
                            grid-template-columns: repeat(2, minmax(322px, 768px));
                        }
                    .result_item {
                            max-width: 768px;
                        width: 100%;
                            min-width: 322px;
                        background-color: $white;
                        border: 1px solid $grey-03;
                        padding: 20px 20px 22px 20px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: flex-start;
                        gap: 24px;
                        border-radius: 3px;

                            @include laptop {
                                width: 100%;
                            }

                        @include mobile {
                            width: 100%;
                        }

                        .item_title {
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-start;
                            margin-bottom: -8px;

                            @include mobile {
                                align-items: center;
                                align-content: center;
                            }

                            .icon {
                                width: 48px;
                                height: 48px;
                                margin-right: 20px;

                                @include mobile {
                                    width: 40px;
                                    height: 40px;
                                }

                                .no_image {
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            .user_name {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: flex-start;
                                gap: 2px;
                                height: 100%;
                                .name{
                                    font-family: 'Gotham-Medium';
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 20px;
                                    line-height: 20px;
                                    color: $grey-06;
                                    @include mobile {
                                        font-size: 16px;
                                    }
                                }
                            }
                        }

                        .data_row {
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-start;
                            gap: 32px;
                            width: 100%;
                            @include mobile {
                                width: 100%;
                            }

                            &.double {
                                .data_item {
                                    @include mobile {
                                        width: 50%;
                                    }
                                    &:first-child{
                                        width: 55%;
                                    }
                                    width: 45%;
                                    span {
                                        width: 100%;
                                        overflow: hidden;
                                        width: 100%;
                                        height: fit-content;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 2;
                                        -webkit-box-orient: vertical;
                                        @include mobile {
                                            overflow: hidden;
                                            width: 100%;
                                            height: fit-content;
                                            display: -webkit-box;
                                            -webkit-line-clamp: 2;
                                            -webkit-box-orient: vertical;
                                        }
                                    }
                                }
                            }

                            .data_item {
                                display: flex;
                                justify-content: flex-start;
                                align-items: flex-start;
                                flex-direction: column;
                                overflow: hidden;
                                span {
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                    gap: 10px;
                                    width: 100%;
                                    .value_text{
                                        font-family: 'Gotham-light';
                                        font-style: normal;
                                        font-weight: 325;
                                        font-size: 14px;
                                        line-height: 16px;
                                        width: 100%;
                                        overflow: hidden;
                                        height: -moz-fit-content;
                                        height: fit-content;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 2;
                                        -webkit-box-orient: vertical;
                                        white-space: pre-wrap;
                                        &.email{
                                            cursor: pointer;
                                        }
                                    }
                                    .copy_btn{
                                        width: 35px;
                                        height: 35px;
                                        cursor: pointer;
                                        border-radius: 50%;
                                        aspect-ratio: 1/1;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        padding: 5px;
                                        transition: all .3s ease-in-out;
                                        &:hover{
                                            background-color: $secondary-01;
                                        }
                                    }
                                    @include mobile{
                                        overflow: hidden;
                                        height: -moz-fit-content;
                                        height: fit-content;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 2;
                                        -webkit-box-orient: vertical;
                                        white-space: pre-wrap;
                                    }
                                }
                                .title {
                                    color: $secondary;
                                }

                                .value {
                                    color: $grey-06;
                                }
                            }
                        }
                    }
                }

                &.list {
                    grid-template-columns: 100%;

                    .result_item {
                        width: 100%;
                        background-color: $white;
                        border: 1px solid $grey-03;
                        padding: 20px 20px 15px 20px;
                        border-radius: 3px;

                        .user_data {
                            display: grid;
                            grid-template-columns: 48px 300px 173px 173px 205px 147px 149px;
                            justify-content: flex-start;
                            column-gap: 33px;

                            .data_column {
                                display: flex;
                                justify-content: flex-start;
                                flex-direction: column;
                                gap: 7px;
                                &:nth-child(2) {
                                    margin-left: -15px;
                                }
                                .icon {
                                    width: 48px;
                                    height: 48px;

                                    .no_image {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }

                                    .data_item {
                                        display: flex;
                                        justify-content: flex-start;
                                        align-items: flex-start;
                                        flex-direction: column;
                                        height: 60px;
                                        &:first-child {
                                            height: 48px;
                                            position: relative;
                                            z-index: 9;
                                            margin-bottom: 4px;
                                            .job_title, .user_name {
                                                min-width: 500px;
                                                @include laptop {
                                                    min-width: unset;
                                                }
                                            }
                                        }

                                    .user_name, .job_title {
                                        font-family: 'Gotham-Medium';
                                        font-style: normal;
                                        font-weight: 500;
                                        font-size: 20px;
                                        line-height: 24px;
                                        color: $grey-06;
                                    }

                                    .job_title{
                                        height: 100%;
                                        display: flex;
                                        justify-content: flex-start;
                                        align-items: center;
                                    }

                                    .user_name {
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;
                                        align-items: flex-start;
                                        gap: 2px;
                                        height: 100%;
                                        .name{
                                            font-family: 'Gotham-Medium';
                                            font-style: normal;
                                            font-weight: 500;
                                            font-size: 20px;
                                            line-height: 20px;
                                            color: $grey-06;
                                            @include mobile {
                                                font-size: 16px;
                                            }
                                        }
                                    }

                                        span {
                                            font-family: 'Gotham-Light';
                                            font-style: normal;
                                            font-weight: lighter;
                                            font-size: 14px;
                                            line-height: 16px;
                                            width: 100%;
                                        }

                                    .title {
                                        color: $secondary;
                                        height: 20px;
                                    }
                                    .value {
                                        display: flex;
                                        justify-content: flex-start;
                                        align-items: center;
                                        gap: 10px;
                                        width: 95%;
                                        &.email{
                                            margin-top: -10px;
                                            .ms-TooltipHost{
                                                width: 90%;
                                            }
                                            .value_text{
                                                white-space: nowrap;
                                                width: 100%;
                                                text-overflow: ellipsis;
                                                display: block;
                                                cursor: pointer;
                                            }
                                        }
                                        .value_text{
                                            font-family: 'Gotham-light';
                                            font-style: normal;
                                            font-weight: 325;
                                            font-size: 14px;
                                            line-height: 16px;
                                            width: 100%;
                                            overflow: hidden;
                                            height: -moz-fit-content;
                                            height: fit-content;
                                            display: -webkit-box;
                                            -webkit-line-clamp: 2;
                                            -webkit-box-orient: vertical;
                                            white-space: pre-wrap;
                                        }
                                        .copy_btn{
                                            width: 35px;
                                            height: 35px;
                                            cursor: pointer;
                                            border-radius: 50%;
                                            aspect-ratio: 1/1;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            padding: 5px;
                                            transition: all .3s ease-in-out;
                                            &:hover{
                                                background-color: $secondary-01;
                                            }
                                        }
                                        @include mobile{
                                            overflow: hidden;
                                            height: -moz-fit-content;
                                            height: fit-content;
                                            display: -webkit-box;
                                            -webkit-line-clamp: 2;
                                            -webkit-box-orient: vertical;
                                            white-space: pre-wrap;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
            .results_all_users{
                margin-top: 23px;
                padding-bottom: 23px;
                border-bottom: 1px solid $grey-04;
                .users_results{
                    grid-template-columns: 100% !important;
                }
            }
        .result_item {
            border-bottom: 1px solid $grey-04;
            padding: 34px 5px;
            display: flex;
            justify-content: flex-start;
            gap: 20px;

            .icon {
                display: flex;
                align-items: flex-start;
                align-content: flex-start;
                justify-content: flex-start;

                @include mobile {
                    align-items: center;
                    align-content: center;
                    justify-content: center;
                }

                .site_icon {
                    width: 40px;
                    height: 40px;
                    color: $white;
                    background-color: $secondary;
                    display: flex;
                    align-items: center;
                    align-content: center;
                    justify-content: center;
                    font-family: 'Gotham-Book';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 20px;
                    border-radius: $border_radius;

                    @include mobile {
                        width: 38px;
                        height: 38px;
                    }
                }

                .file_icon {
                    width: 42px;

                    @include mobile {
                        height: 100%;
                    }

                    i {
                        width: 100%;
                        height: 100%;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }

                .avatar {
                    width: 50px;
                    height: 50px;
                    aspect-ratio: 1/1;
                    border-radius: 50%;
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .no_image {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    color: $white;
                    font-family: 'Gotham-Book';
                    font-style: normal;
                    font-weight: 300;
                    font-size: 16px;
                    background-color: $grey-05;
                }
            }

            .title {
                @include mobile {
                    width: 100%;
                    gap: 10px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
                    width: calc(100% - 62px);
                .title_link {
                    font-family: 'Gotham-Medium';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 18px;
                    color: $secondary-04;
                    text-align: left;
                    text-decoration: none;

                    @include mobile {
                        color: $primary;
                        font-size: 18px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        line-height: 20px;
                    }
                }

                .description {
                    padding: 0px;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    margin-top: 8px;

                    @include mobile {
                        margin-top: 8px;
                    }

                    span, a {
                        font-family: 'Gotham-Light';
                        font-style: normal;
                        color: $grey-06;
                        text-decoration: none;
                        font-weight: 325;
                        font-size: 16px;
                    }

                    a {
                        &:hover {
                            color: $grey-07;
                        }
                    }

                    b {
                        font-weight: 500;
                        font-family: 'Gotham-Medium';
                    }

                    .ubication {
                        font-weight: 500;
                        font-family: 'Gotham-Light';
                        font-size: 16px;
                        line-height: 16px;

                        @include mobile {
                            font-size: 14px;
                            line-height: 14px;
                            font-family: 'Gotham-Light';
                            font-weight: 325;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }

                        .link {
                            font-weight: 500;
                            font-family: 'Gotham-Light';
                            font-size: 16px;
                            line-height: 16px;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            @include mobile {
                                font-size: 14px;
                                line-height: 14px;
                                font-family: 'Gotham-Light';
                                font-weight: 325;
                            }
                        }

                    .author {
                        font-weight: 100;
                        font-size: 16px;
                        line-height: 18px;

                        @include mobile {
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }
                }
            }

            .preview {
                min-width: 208px;
                height: 117px;
                display: block;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}

    .quick_search, .search_results {
        margin-top: 3.5rem;
        width: 100%;
        @include mobile {
            margin-top: 1rem;
        }
        @include tablet {
            margin-top: 2rem;
        }
        .title {
            h1 {
                @include heading-02;
                font-weight: 325;
                color: $grey-06;
                padding-left: 0;
                @include tablet {
                    font-size: 28px;
                }
                @include mobile {
                    font-size: 24px;
                }
            }
        }
        .cards_wrapper {
            margin-top: 30px;
            display: flex;
            flex-direction: row;
            gap: 32px;
            @include mobile {
                flex-direction: column;
                gap: 16px;
            }
            @include tablet {
                flex-direction: column;
                gap: 16px;
            }
            .cards_section{
                @include mobile {
                    width: 100%;
                }
                @include tablet {
                    width: 100%;
                }
                .title {
                    font-family: 'Gotham-Book';
                    font-weight: 325;
                    font-size: 24px;
                    line-height: 28px;
                    color: $grey-06;
                    padding-bottom: 18px;
                    @include tablet {
                        font-size: 20px;
                        font-family: 'Gotham-Book';
                    }
                    @include mobile {
                        font-size: 18px;
                        font-family: 'Gotham-Light';
                    }
                }
                &:last-child{
                    .cards{
                        grid-template-columns: repeat(1, minmax(228px, 328px));
                        @include mobile {
                            grid-template-columns: repeat(2, minmax(136px, 310px));
                        }
                        @include tablet {
                            grid-template-columns: repeat(2, minmax(136px, 310px));
                        }
                        @include laptop {
                            grid-template-columns: repeat(1, minmax(210px, 380px));
                        }
                        &.hotel{
                            grid-template-columns: repeat(2, minmax(228px, 328px));
                            @include mobile {
                                grid-template-columns: repeat(2, minmax(136px, 310px));
                            }
                        }
                    }
                }
                .cards{
                    display: grid;
                    grid-template-columns: repeat(3, minmax(228px, 328px));
                    grid-template-rows: auto;
                    column-gap: 32px;
                    row-gap: 28px;
                    align-content: flex-start;
                    justify-content: flex-start;
                    justify-items: flex-start;
                    @include laptop {
                        grid-template-columns: repeat(2, minmax(210px, 380px));
                    }
                    @include mobile {
                        grid-template-columns: repeat(2, minmax(136px, 310px));
                    }
                    @include tablet {
                        grid-template-columns: repeat(2, minmax(136px, 310px));
                    }
                    .card_item {
                        background-color: $white;
                        border: 1px solid $grey-02;
                        min-height: 112px;
                        height: 100%;
                        max-width: 328px;
                        width: 100%;
                        border-radius: $border_radius;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        align-content: center;
                        transition: all .2s ease-in;
                        @include laptop{
                            max-width: 380px;
                        }
                        @include mobile {
                            max-width: 100%;
                            min-height: 150px;
                            height: 100%;
                        }
                        @include tablet {
                            max-width: 350px;
                            min-width: 136px;
                            min-height: 150px;
                            height: 100%;
                        }
                        &:hover {
                            border-color: $grey-05;
                        }
                        &.image {
                            @include mobile {
                                padding: 10px;
                            }
                            @include tablet {
                                padding: 10px;
                            }
                            a {
                                justify-content: center;
                                align-items: center;
                                align-content: center;
                                padding: 10px;
                                width: 100%;
                                height: 100%;
                                display: flex;
        
                                img {
                                    max-width: 200px;
                                    width: 100%;
                                    height: auto;
                                    @include mobile {
                                        max-width: 100%;
                                    }
                                    @include tablet {
                                        max-width: 50%;
                                    }
                                }
                            }
                        }
        
                        .wrapper {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: center;
                            align-content: center;
                            gap: 12px;
                            text-decoration: none;
                            width: 100%;
                            height: 100%;
                            padding: 12px 16px 12px 16px;
                            &:focus-visible {
                                outline: none;
                            }
                            @include mobile {
                                flex-direction: column;
                                align-items: center;
                                align-content: center;
                                justify-content: center;
                                gap: 2px;
                                height: 100%;
                                width: 100%;
                                padding: 10px;
                            }
                            @include tablet {
                                flex-direction: column;
                                align-items: center;
                                align-content: center;
                                justify-content: center;
                                gap: 2px;
                                height: 100%;
                                width: 100%;
                                padding: 10px;
                            }
                            .card_title {
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                align-content: center;
                                gap: 24px;
                                width: 100%;
                                height: 50px;
                                @include mobile {
                                    flex-direction: column;
                                    align-items: center;
                                    align-content: center;
                                    justify-content: flex-start;
                                    font-size: 14px;
                                    line-height: 18px;
                                    gap: 12px;
                                    height: 100%;
                                    width: 100%;
                                    overflow: hidden;
                                }
                                @include tablet {
                                    flex-direction: column;
                                    align-items: center;
                                    align-content: center;
                                    justify-content: flex-start;
                                    font-size: 16px;
                                    line-height: 18px;
                                    gap: 12px;
                                    height: 100%;
                                    width: 100%;
                                }
                                @include laptop {
                                    gap: 18px;
                                }
                                .title {
                                    text-align: left;
                                    max-width: 80%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;
                                    padding: 0px;
                                    @include tablet {
                                        text-align: center;
                                        height: 40px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        align-content: center;
                                    }
                                    @include mobile {
                                        text-align: center;
                                        height: 40px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        align-content: center;
                                    }
                                    span{
                                        text-transform: uppercase;
                                        color: $grey-06;
                                        font-size: 17.5px;
                                        word-spacing: 0px;
                                        line-height: 24px;
                                        font-family: "Gotham-Light";
                                        font-weight: 325;
                                        @include laptop {
                                            font-size: 15px;
                                            overflow: hidden;
                                            display: -webkit-box;
                                            -webkit-line-clamp: 2;
                                            -webkit-box-orient: vertical;
                                            -webkit-box-align: center;
                                        }
                                        @include tablet {
                                            font-size: 14px;
                                            display: -webkit-box;
                                            -webkit-line-clamp: 2;
                                            -webkit-box-orient: vertical;
                                            -webkit-box-align: center;
                                            height: 35px;
                                            line-height: 18px;
                                            overflow: hidden;
                                        }
                                        @include mobile {
                                            font-size: 13px;
                                            line-height: 15px;
                                            min-width: 130px;
                                            width: 100%;
                                        }
                                    }
                                }
                                .icon {
                                    width: 40px;
                                    height: 40px;
                                    display: flex;
                                    aspect-ratio: 1/1;
                                    @include laptop {
                                        width: 35px;
                                        height: 35px;
                                    }
                                }
                            }
                            .links {
                                width: 100%;
                                display: flex;
                                justify-content: flex-end;
                                align-items: center;
                                align-content: center;
                                gap: 12px;
        
                                @include mobile {
                                    justify-content: center;
                                    gap: 8px;
                                }
                                @include tablet {
                                    justify-content: center;
                                    gap: 8px;
                                }
                                span{
                                    color: $primary;
                                    font-family: 'Gotham-Book';
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 16px;
                                    @include laptop {
                                        font-size: 15px;
                                    }
                                    @include mobile {
                                        font-size: 12px;
                                    }
                                    @include tablet {
                                        font-size: 12px;
                                    }
                                }
                                a {
                                    text-decoration: none;
                                    text-transform: uppercase;
                                    color: $secondary;
                                    font-family: 'Gotham-Book';
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 16px;
                                    line-height: 16px;
                                    &:hover {
                                        color: $secondary-02;
                                    }
                                    @include laptop {
                                        font-size: 15px;
                                    }
                                    @include mobile {
                                        font-size: 12px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
