@import './variables.scss';
@import './mixins.scss';

.consent_wrapper{
    margin-top: 6rem;
    .consent_text{
        .text{
            span, div, p {
                font-family: 'Gotham-Light' !important;
                font-style: normal;
                font-weight: 325;
                font-size: 16px;
                line-height: 22px;  
                color: $grey-06;
            }
            b{
                font-family: 'Gotham-Book' !important;
                font-weight: 700 !important;
            }
            i{
                font-style: italic !important;
            }
            
        }
        .consent_form{
            margin-top: 40px;
            .checkbox_wrapper{
                position: relative;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                align-content: center;
                gap: 10px;
                input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    left: 0;
                    top: 0;
                    z-index: 99;
                    width: 21px;
                    height: 21px;
                }
                .checkmark {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 21px;
                    width: 21px;
                    background-color: transparent;
                    border: 2px solid $secondary;
                    border-radius: 3px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    &::after {
                        content: "";
                        display: none;
                        width: 6px;
                        height: 10px;
                        margin-top: -3px;
                        border: solid white;
                        border-width: 0 2px 2px 0;
                        -webkit-transform: rotate(45deg);
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                }
                input:checked ~ .checkmark {
                    background-color: $secondary;
                }
                input:checked ~ .checkmark::after {
                    display: block !important;
                }
                .label {
                    margin-left: 30px;
                    font-family: 'Gotham-Light';
                    font-style: normal;
                    font-weight: 325;
                    font-size: 16px;
                    line-height: 20px;
                    color: $grey-06;
                }
            }
            .send_button{
                margin-top: 40px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                align-content: center;
                gap: 20px;
                .btn{
                    padding: 9px 30px;
                    border-radius: 3px;
                    border: none;
                    text-transform: uppercase;
                    background-color: $grey-04;
                    color: $grey-06;
                    font-family: 'Gotham-Light';
                    font-style: normal;
                    font-weight: 325;
                    font-size: 12px;
                    line-height: 16px;
                    cursor: default;
                    outline: none;
                    box-shadow: none !important;
                    &.active {
                        background-color: $secondary;
                        color: $white;
                        cursor: pointer;
                        &:active{
                            outline: none;
                            box-shadow: none !important;
                            background-color: $secondary-06 !important;
                        }
                    }
                    .loader{
                        display: flex;
                        justify-content: center;
                        align-items: baseline;
                        gap: 15px;
                        .dots{
                            .dot{
                              width: 4px;
                              aspect-ratio: 1/1;
                              border-radius:100%;
                              float:left;
                              margin-right:5px;
                              animation: bounce 1s infinite;
                              -webkit-animation:bounce 1s infinite;
                              background: $white;
                              &:nth-child(1){
                                animation-delay: .1s;
                                -webkit-animation-delay:.1s;
                              }
                              &:nth-child(2){
                                animation-delay: .3s;
                                -webkit-animation-delay:.3s;
                              }
                              &:nth-child(3){
                                animation-delay: .5s;
                                -webkit-animation-delay:.5s;
                              }
                            }
                          }
                    }
                }
            }
            .consent_error{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                align-content: center;
                gap: 10px;
                .icon{
                    width: 28px;
                    aspect-ratio: 1/1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    svg{
                        width: 18px;
                        path{
                            fill: $secondary;
                        }
                    }
                }
                .text{
                    font-family: 'Gotham-Light';
                    font-style: normal;
                    font-weight: 325;
                    font-size: 16px;
                    line-height: 22px;
                    color: $grey-06;
                    text-align: left;
                    @include laptop {
                        font-size: 14px;
                        line-height: 18px;
                    }
                }
            }
        }
    }
}

@-webkit-keyframes bounce {
    0%, 100% {
      opacity:1;
    }
    60% {
      opacity:.0;
    }
}
  
@keyframes bounce {
    0%, 100% {
        opacity:1;
    }
    60% {
        opacity:.0;
    }
}