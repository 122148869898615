@import './variables';
@import './mixins';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $grey-01;
  min-height: 100vh;
  #root{
    min-height: 100vh;
  }
}

*{
  box-sizing: border-box;
}

main {
  padding-top: 80px;
  @include mobile {
    padding-top: 70px;
  }
}

.fluent_tooltip_short{
  width: fit-content;
  max-width: 200px;
  padding: 16px;
  &::after{
    inset: 0px !important;
  }
  p, span, div{
    font-family: 'Gotham-Light';
    font-style: normal;
    font-weight: 325;
    font-size: 14px;
    line-height: 16px;
    color: $grey-05;
  }
}

.fluent_tooltip_medium{
  width: fit-content;
  max-width: 300px;
  padding: 16px;
  &::after{
    inset: 0px !important;
  }
  .quickaccess_tooltip {
    b{
      font-family: 'Gotham-Book';
      font-style: normal;
      font-weight: 325;
      font-size: 14px;
      line-height: 16px;
      color: $grey-06;
    }
  }
  p, span, div{
    font-family: 'Gotham-Light';
    font-style: normal;
    font-weight: 325;
    font-size: 14px;
    line-height: 16px;
    color: $grey-05;
  }
}

.fluent_tooltip{
  width: fit-content;
  max-width: fit-content;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 16px;
  &::after{
    inset: 0px !important;
  }
  &.low_padding{
    padding: 8px;
  }
  p, span, div{
    font-family: 'Gotham-Light';
    font-style: normal;
    font-weight: 325;
    font-size: 14px;
    line-height: 18px;
    color: $grey-05;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*:focus-visible{
  outline: none;
}

.loading_page{
  max-width: 100vw;
  max-height: 100vh;
  min-height: 100vh;
  width: 100%;
  height: 100%;
  background-color: #F5F5F5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  h1{
    font-family: 'Gotham-Light';
    font-style: normal;
    font-weight: 325;
    font-size: 36px;
    line-height: 40px;
    display: flex;
    justify-content: center;
    align-items: baseline;
    gap: 15px;
    color: $primary;
    .dots{
      .dot{
        width: 8px;
        aspect-ratio: 1/1;
        border-radius:100%;
        float:left;
        margin-right:5px;
        animation: bounce 1s infinite;
        -webkit-animation:bounce 1s infinite;
        background: $secondary;
        &:nth-child(1){
          animation-delay: .1s;
          -webkit-animation-delay:.1s;
        }
        &:nth-child(2){
          animation-delay: .3s;
          -webkit-animation-delay:.3s;
        }
        &:nth-child(3){
          animation-delay: .5s;
          -webkit-animation-delay:.5s;
        }
      }
    }
  }
  h3{
    font-family: 'Gotham-Light';
    font-style: normal;
    font-weight: 325;
    font-size: 20px;
    line-height: 40px;
    color: $primary;
  }
}

@-webkit-keyframes bounce {
  0%, 100% {
    opacity:1;
  }
  60% {
    opacity:.0;
  }
}

@keyframes bounce {
  0%, 100% {
    opacity:1;
  }
  60% {
    opacity:.0;
  }
}