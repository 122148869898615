@import './variables.scss';
@import './mixins.scss';

.frecently_used_forms{
    width: 100%;
    .forms{
        width: 100%;
        background-color: #fff;
        height: 280px;
        border-radius: $border_radius;
        border: 1px solid $grey-02;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        padding:80px 88px 55px 88px;
        gap: 152px;
        @include laptop {
            padding: 60px 60px 30px 60px;
            gap: 50px;
        }
        @include mobile {
            flex-direction: column;
            height: auto;
            gap: 50px;
            padding: 18px 16px;
        }
        .area_title{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 5px;
            max-width: 30%;
            @include laptop {
                max-width: 300px;
            }
            @include mobile{
                gap: 12px;
                max-width: 100%;
            }
            .title{
                font-family: 'Gotham-Book';
                font-style: normal;
                font-weight: 325;
                font-size: 36px;
                line-height: 40px;
                color: $grey-06;
                @include laptop {
                    font-size: 30px;
                    line-height: 36px;
                }
                @include mobile{
                    font-size: 22px;
                    line-height: 24px;
                }
            }
            .sub_title{
                font-family: 'Gotham-Light';
                font-style: normal;
                font-weight: 325;
                font-size: 16px;
                line-height: 20px;
                color: $grey-06;
                @include mobile{
                    font-size: 14px;
                    line-height: 16px;
                }
            }
        }
        .frequent_forms{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 44px;
            width: 70%;
            @include mobile{
                flex-wrap: wrap;
                column-gap: 5%;
                row-gap: 50px;
                width: 100%;
                height: 100%;
            }
            .no_forms{
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                .title{
                    font-family: 'Gotham-Book';
                    font-style: normal;
                    font-weight: 325;
                    font-size: 30px;
                    line-height: 40px;
                    color: $grey-06;
                    @include mobile{
                        font-size: 18px;
                        line-height: 24px;
                    }
                }
                .subtitle{
                    font-family: 'Gotham-Light';
                    font-style: normal;
                    font-weight: 325;
                    font-size: 16px;
                    line-height: 20px;
                    color: $grey-06;
                    @include mobile{
                        font-size: 14px;
                        line-height: 16px;
                    }
                }
            }
            .form{
                width: 100%;
                max-width: 240px;
                min-width: 150px;
                max-height: 176px;
                min-height: 156px;
                aspect-ratio: 3/2;
                border: 1px solid $grey-03;
                border-radius: $border_radius;
                transition: all .2s;
                @include mobile{
                    width: 47.5%;
                    height: 156px;
                }
                &:hover{
                    border-color: $grey-05;
                }
                .wrapper{
                    width: 100%;
                    height: 100%;
                    display: block;
                    margin: 0px;
                    padding: 0px;
                    position: relative;
                    border-radius: $border_radius;
                    text-decoration: none;
                    color: $grey-06;
                    .icon{
                        position: absolute;
                        top: -30px;
                        left: 16px;
                        background-color: $secondary;
                        width: 60px;
                        aspect-ratio: 1/1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: $border_radius;
                        svg{
                            path{
                                fill: $white;
                            }
                        }
                        @include mobile{
                            width: 52px;
                        }
                    }
                    .text{
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        padding: 55px 20px 16px 16px;
                        gap: 10px;
                        word-wrap: break-word;
                        text-transform: capitalize;
                        @include mobile{
                            padding: 35px 16px 16px 16px;
                            gap: 8px;
                        }
                        .departement{
                            font-family: 'Gotham-Medium';
                            font-style: normal;
                            font-size: 16px;
                            line-height: 20px;
                            @include mobile {
                                font-size: 12px;
                                line-height: 20px;
                            }
                        }
                        .title{
                            font-family: 'Gotham-Light';
                            font-style: normal;
                            font-weight: 325;
                            font-size: 20px;
                            width: 100%;
                            line-height: 24px; overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            &:after{
                                content: '';
                                display: block;
                                clear: both;
                            }
                            @include mobile {
                                font-size: 16px;
                                line-height: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.tasks_wrapper{
    .tasks{
        width: 100%;
        display: flex;
        background-color: $grey-06;
        border-radius: 4px;
        padding: 23px 86px;
        margin-top: 24px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        @include mobile {
            padding: 16px 16px;
        }
        .task{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            @include mobile {
                flex-direction: column;
                gap: 16px;
            }
            .task_title{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 4px;
                .icon{
                    width: 32px;
                    aspect-ratio: 1/1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @include mobile {
                        width: 28px;
                    }
                    svg{
                        width: 24px;
                        @include mobile {
                            width: 20px;
                        }
                    }
                }
                .title{
                    font-family: 'Gotham-Medium';
                    font-style: normal;
                    font-weight: 100;
                    font-size: 24px;
                    line-height: 24px;
                    color: $white;
                    @include mobile {
                        font-size: 18px;
                        line-height: 24px;
                    }
                }
            }
            .task_description{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 120px;
                @include mobile {
                    justify-content: space-between;
                    gap: 16px;
                }
                .title{
                    font-family: 'Gotham-Medium';
                    font-style: normal;
                    font-weight: 100;
                    font-size: 20px;
                    line-height: 24px;
                    color: $white;
                    @include mobile {
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
                .date{
                    font-family: 'Gotham-Light';
                    font-style: normal;
                    font-weight: 325;
                    font-size: 20px;
                    line-height: 24px;
                    color: $white;
                    @include mobile {
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }
        }
    }
}
.forms_area{
    margin-top: 84px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;
    @include mobile{
        margin-top: 24px;
        gap: 12px;
    }
    .area_title{
        width: 100%;
        .title{
            font-family: 'Gotham-Book';
            font-style: normal;
            font-weight: 325;
            font-size: 36px;
            line-height: 40px;
            color: $grey-06;
            @include mobile{
                font-size: 20px;
                line-height: 36px;
            }
        }
    }
    .forms_toolbar{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        @include mobile{
            flex-direction: column;
            gap: 21px;
        }
        .search{
            width: 688px;
            height: 100%;
            @include mobile{
                width: 100%;
            }
            .input_group{
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: .25rem;
                position: relative;
                width: 100%;
                border-radius: 3px;
                @include mobile{
                    min-width: 100%;
                    max-width: 100%;
                }
                .fluent_label{
                    left: 0;
                    top: -28px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    align-content: center;
                    gap: 10px;
                    height: 28px;
                    width: 100%;
                    .title{
                        font-family: 'Gotham-Book';
                        text-transform: uppercase;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 18px;
                        letter-spacing: 0.2px;
                        color: $grey-06;
                        width: fit-content;
                        @include mobile{
                            font-size: 11px;
                            line-height: 14px;
                        }
                    }
                }
                .fluent_input{
                    width: 100%;
                    height: 44px;
                    padding: 13px 50px 13px 24px;
                    border: 1px solid $grey-04;
                    font-weight: 325;
                    color: $grey-05;
                    border-radius: 3px;
                    @include text-01;
                    @include mobile{
                        font-size: 13px;
                    }
                    &:focus, &:active{
                        outline: none;
                        border-color: $black;
                    }
                    &:hover {
                        outline: none;
                        border-color: $black;
                    }
                }
                .input_icon{
                    right: 10px;
                    top: 90%;
                    width: 34px;
                    position: absolute;
                    aspect-ratio: 1/1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    transition: all .3s ease-in-out;
                    border-radius: 50%;
                    transform: translateY(-90%);
                    &:hover{
                        background-color: $secondary-01;
                        cursor: pointer;
                    }
                    .icon{
                        width: 34px;
                        aspect-ratio: 1/1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        align-content: center;
                        svg{
                            width: 20px;
                            aspect-ratio: 1/1;
                            path{
                                fill: $secondary
                            } 
                        }
                    }
                }
            }
        }
        .filters{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            gap: 32px;
            height: 100%;
            width: 400px;
            @include mobile{
                justify-content: space-between;
                width: 100%;
                gap: 16px;
            }
            .filter_filter{
                .filter_wrapper{
                    &.open{
                        .filter_menu{
                            height: 120px !important;
                        }
                    }
                }
            }
            .filter_sort, .filter_filter{
                @include mobile{
                    width: 160px;
                }
                .filter_wrapper{
                    position: relative;
                    &.open{
                        .filter .drop_down{
                            svg{
                                transform: rotate(180deg);
                            }
                        }
                        .filter_menu{
                            visibility: visible;
                            opacity: 1;
                            height: 80px;
                            background-color: $white;
                            position: absolute;
                            z-index: 100;
                            width: 100%;
                            box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.15);
                            border-radius: 3px;
                            .filter_item{
                                width: 100%;
                                padding: 10px 16px;
                                border-bottom: 1px solid $grey-03;
                                font-family: 'Gotham-Light';
                                font-style: normal;
                                font-weight: 325;
                                font-size: 16px;
                                line-height: 20px;
                                color: $grey-06;
                                height: 40px;
                                &.active{
                                    color: $secondary;
                                }
                                &:last-child{
                                    border-bottom: none;
                                    border-bottom-left-radius: 3px;
                                    border-bottom-right-radius: 3px;
                                }
                                &:first-child{
                                    border-top-left-radius: 3px;
                                    border-top-right-radius: 3px;
                                }
                                &:hover{
                                    background-color: $grey-01;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                    .filter {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        align-content: center;
                        padding: 8px;
                        border: 1px solid $grey-04;
                        border-radius: 3px;
                        width: 210px;
                        background-color: $white;
                        cursor: pointer;
                        @include mobile {
                            width: 100%;
                        }
                        .label {
                            display: block;
                            text-align: left;
                            font-family: 'Gotham-Light';
                            font-style: normal;
                            font-weight: 325;
                            font-size: 16px;
                            color: $grey-06;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;
                            width: 180px;
                            cursor: pointer;
                            @include mobile {
                                font-size: 15px;
                            }
                        }
                        .drop_down {
                            width: 20px;
                            height: 20px;
                            aspect-ratio: 1/1;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            align-content: center;
                            cursor: pointer;
                            svg{
                                transition: all .3s ease-in-out;
                            }
                        }
                    }
                    .filter_menu{
                        height: 0px;
                        visibility: hidden;
                        opacity: 0;
                    }
                }
            }
            .styles_toolbar {
                .wrapper {
                    width: 100%;
                    height: 42px;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    align-content: center;
                    .icon {
                        width: 44px;
                        height: 44px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        align-content: center;
                        border-radius: 3px;
                        transition: all .3s ease-in-out;
                        &.list{
                            svg{
                                width: 18px;
                                height: 18px;
                            }
                        }
                        svg {
                            width: 15px;
                            height: 15px;
                            path {
                                fill: $grey-06;
                            }
                        }
                        &.active {
                            svg path {
                                fill: $secondary;
                            }
                            &:hover {
                                cursor: pointer;
                                svg path {
                                    fill: $secondary;
                                }
                            }
                        }
                        &:hover {
                            cursor: pointer;
                            svg path {
                                fill: $grey-04;
                            }
                        }
                        &:active, &:focus{
                            background-color: $grey-02;
                        }
                    }
                }
            }
        }
    }
    .forms_list{
        @include mobile{
            width: 100%;
        }
        .form_group{
            @include mobile{
                margin-top: 27px;
            }
            .group_title{
                .title{
                    font-family: 'Gotham-Medium';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 28px;
                    color: $grey-06;
                    @include mobile{
                        font-size: 18px;
                        line-height: 24px;
                    }
                }
            }
            .group_forms{
                display: grid;
                grid-template-columns: repeat(3, minmax(248px, 448px));
                column-gap: 32px;
                row-gap: 23px;
                margin: 25px 0px 71px 0px;
                @include mobile{
                    grid-template-columns: repeat(1, 1fr);
                    row-gap: 12px;
                    margin: 13px 0px 32px 0px;
                }
                .form_item{
                    width: 100%;
                    min-width: 248px;
                    max-width: 448px;
                    background-color: $white;
                    border: 1px solid $grey-02;
                    border-radius: 3px;
                    padding: 16px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    align-content: center;
                    gap: 20px;
                    text-decoration: none;
                    &:hover{
                        border-color: $grey-03;
                    }
                    @include laptop{
                        align-items: flex-start;
                        align-content: flex-start;
                    }
                    @include mobile{
                        width: 100%;
                    }
                    .item_icon{
                        width: 48px;
                        height: 48px;
                        aspect-ratio: 1/1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        align-content: center;
                        border-radius: 3px;
                        background-color: $grey-02;
                        svg{
                            width: 16px;
                            path{
                                fill: $grey-06;
                            }
                        }
                    }
                    .item_content{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        gap: 6px;
                        .title{
                            font-family: 'Gotham-Medium';
                            font-style: normal;
                            font-weight: 100;
                            font-size: 16px;
                            line-height: 20px;
                            color: $grey-06;
                            @include laptop {
                                font-size: 15px;
                                line-height: 18px;
                                height: 35px;
                            }
                            @include mobile{
                                font-size: 14px;
                                line-height: 18px;
                            }
                        }
                        .content{
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            align-content: center;
                            gap: 20px;
                            .label{
                                font-family: 'Gotham-Light';
                                font-style: normal;
                                font-weight: 325;
                                font-size: 16px;
                                line-height: 16px;
                                color: $grey-06;
                                @include laptop {
                                    font-size: 14px;
                                    line-height: 20px;
                                }
                                @include mobile{
                                    font-size: 12px;
                                    line-height: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}