@import './variables.scss';
@import './mixins.scss';

.pagination_warper{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding-top: 80px;
    gap: 40px;
    @include mobile {
        gap: 15px;
        padding-top: 40px;
    }
    @include tablet {
        gap: 15px;
        padding-top: 40px;
    }
    .dots{
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        border: 0;
        font-family: 'Gotham-Book';
        font-style: normal;
        font-weight: 325;
        line-height: 16px;
        color: $grey-06;
        gap: 10px;
        text-transform: uppercase;
        background-color: transparent;
        cursor: pointer;
        padding: 14px;
        border-radius: 4px;
        position: relative;
        width: 44px;
        height: 44px;
        font-size: 18px;
        &:hover{
            background-color: transparent !important;
            color: $grey-06 !important;
        }
    }
    .button{
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        border: 0;
        font-family: 'Gotham-Book';
        font-style: normal;
        font-weight: 325;
        font-size: 12px;
        line-height: 16px;
        color: $grey-06;
        gap: 10px;
        text-transform: uppercase;
        background-color: transparent;
        cursor: pointer;
        padding: 14px;
        border-radius: 4px;
        position: relative;
        &.number{
            width: 44px;
            height: 44px;
            font-size: 18px;
            &.current{
                color: $secondary;
            }
        }
        svg{
            width: 16px;
            height: 14px;
            path{
                fill: $secondary;
            }
        }
        &:hover{
            background-color: $secondary-01;
            color: $secondary;
        }
        &:active{
            background-color: $secondary-02;
            color: $secondary;
        }
        &.disabled{
            cursor: default;
            opacity: .9;
            &:hover{
                background-color: transparent;
                color: $grey-06;
            }
            svg{
                path{
                    fill: $grey-06;
                }
            }
        }
    }
    .pagination_numbers{
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        position: relative;
    }
}