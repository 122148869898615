@import './variables.scss';
@import './mixins.scss';

.guides_area{
    width: 100%;
    .guides {
        width: 100%;
        background-color: #fff;
        height: 280px;
        border-radius: $border_radius;
        border: 1px solid $grey-02;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        padding:80px 88px 55px 88px;
        gap: 152px;
        @include laptop {
            padding: 60px 60px 30px 60px;
            gap: 50px;
        }
        @include mobile {
            flex-direction: column;
            height: auto;
            gap: 50px;
            padding: 18px 16px;
        }
        .area_title{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 5px;
            max-width: 30%;
            @include laptop {
                max-width: 300px;
            }
            @include mobile{
                gap: 12px;
                max-width: 100%;
            }
            .title{
                font-family: 'Gotham-Book';
                font-style: normal;
                font-weight: 325;
                font-size: 36px;
                line-height: 40px;
                color: $grey-06;
                @include laptop {
                    font-size: 30px;
                    line-height: 36px;
                }
                @include mobile{
                    font-size: 22px;
                    line-height: 24px;
                }
            }
            .sub_title{
                font-family: 'Gotham-Light';
                font-style: normal;
                font-weight: 325;
                font-size: 16px;
                line-height: 20px;
                color: $grey-06;
                @include mobile{
                    font-size: 14px;
                    line-height: 16px;
                }
            }
        }
        .guides_documents{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 44px;
            width: 70%;
            @include mobile{
                flex-wrap: wrap;
                column-gap: 5%;
                row-gap: 50px;
                width: 100%;
                height: 100%;
            }
            .guide{
                width: 100%;
                max-width: 240px;
                min-width: 150px;
                max-height: 176px;
                min-height: 156px;
                aspect-ratio: 3/2;
                border: 1px solid $grey-03;
                border-radius: $border_radius;
                transition: all .2s;
                @include mobile{
                    width: 47.5%;
                    height: 156px;
                }
                &:hover{
                    border-color: $grey-05;
                }
                .wrapper{
                    width: 100%;
                    height: 100%;
                    display: block;
                    margin: 0px;
                    padding: 0px;
                    position: relative;
                    border-radius: $border_radius;
                    text-decoration: none;
                    color: $grey-06;
                    .icon{
                        position: absolute;
                        top: -30px;
                        left: 16px;
                        background-color: $grey-02;
                        width: 60px;
                        aspect-ratio: 1/1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: $border_radius;
                        svg{
                            path{
                                fill: $white;
                            }
                        }
                        @include mobile{
                            width: 52px;
                        }
                    }
                    .text{
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        align-items: flex-start;
                        padding: 55px 20px 16px 16px;
                        gap: 10px;
                        word-wrap: break-word;
                        text-transform: capitalize;
                        @include mobile{
                            padding: 35px 16px 16px 16px;
                            gap: 8px;
                        }
                        .departement{
                            font-family: 'Gotham-Medium';
                            font-style: normal;
                            font-size: 16px;
                            line-height: 20px;
                            @include mobile {
                                font-size: 12px;
                                line-height: 20px;
                            }
                        }
                        .title{
                            font-family: 'Gotham-Light';
                            font-style: normal;
                            font-weight: 325;
                            font-size: 20px;
                            width: 100%;
                            line-height: 24px; overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                            &:after{
                                content: '';
                                display: block;
                                clear: both;
                            }
                            @include mobile {
                                font-size: 16px;
                                line-height: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.videos_area{
    margin-top: 84px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;
    @include mobile{
        margin-top: 24px;
        gap: 12px;
    }
    .area_title{
        width: 100%;
        .title{
            font-family: 'Gotham-Book';
            font-style: normal;
            font-weight: 325;
            font-size: 36px;
            line-height: 40px;
            color: $grey-06;
            @include mobile{
                font-size: 20px;
                line-height: 36px;
            }
        }
    }
    .videos{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 48px;
        width: 100%;
        @include mobile {
            gap: 18px;
        }
        .video{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 32px;
            border-bottom: 1px solid $grey-03;
            padding-bottom: 48px;
            width: 100%;
            @include mobile {
                padding-bottom: 18px;
                flex-direction: column;
                gap: 12px;
                width: 100%;
            }
            &:last-child{
                border-bottom: none;
            }
            .video_description{
                width: 40%;
                height: auto;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 24px;
                @include mobile {
                    height: auto;
                    width: 100%;
                    max-width: 100%;
                    gap: 8px;
                }
                .description_title{
                    font-family: 'Gotham-Book';
                    font-style: normal;
                    font-weight: 325;
                    font-size: 24px;
                    line-height: 28px;
                    color: $grey-06;
                    @include mobile {
                        font-size: 16px;
                        line-height: 36px;
                    }
                }
                .description_text{
                    font-family: 'Gotham-Light';
                    font-style: normal;
                    font-weight: 325;
                    font-size: 16px;
                    line-height: 22px;
                    color: $grey-06;
                    white-space: pre-line;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: $black;
                    @include mobile {
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
                .files_section{
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    margin-top: 40px;
                    @include mobile {
                        margin-top: 0px;
                    }
                    @include laptop {
                        margin-top: 48px;
                    }
                    .file{
                        background-color: $white;
                        width: 100%;
                        display: flex;
                        justify-content: space;
                        align-items: center;
                        border: 1px solid $grey-02;
                        border-radius: 3px;
                        gap: 20px;
                        padding: 16px;
                        text-decoration: none;
                        transition: all 0.3s ease-in-out;
                        &:hover{
                            border: 1px solid $grey-03;
                        }
                        @include laptop {
                            align-items: flex-start;
                            align-content: flex-start;
                        }
                        .icon{
                            width: 48px;
                            height: 48px;
                            aspect-ratio: 1/1;
                            border-radius: 3px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: $grey-02;
                            i{
                                width: 24px;
                            }
                        }
                        .file_info{
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            align-items: flex-start;
                            gap: 6px;
                            .file_title{
                                font-family: 'Gotham-Medium';
                                font-style: normal;
                                font-weight: 300;
                                font-size: 16px;
                                line-height: 20px;
                                color: $grey-06;
                                @include mobile {
                                    font-size: 15px;
                                    line-height: 18px;
                                }
                            }
                            .short_info{
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                gap: 20px;
                                width: 100%;
                                span{
                                    font-family: 'Gotham-Light';
                                    font-style: normal;
                                    font-weight: 325;
                                    font-size: 16px;
                                    line-height: 16px;
                                    color: $grey-06;
                                    @include mobile {
                                        font-size: 12px;
                                        line-height: 16px;
                                    }
                                    @include laptop {
                                        font-size: 15px;
                                        line-height: 20px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .video_frame{
                width: 60%;
                aspect-ratio: 16/9;
                border-radius: 3px;
                position: relative;
                @include mobile {
                    width: 100%;
                    aspect-ratio: 16/9;
                }
                .overlay{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.5) 58.85%);
                    border-radius: 3px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }
                .video_title{
                    position: absolute;
                    top: 30px;
                    left: 39px;
                    font-family: 'Gotham-Book';
                    font-style: normal;
                    font-weight: 325;
                    font-size: 24px;
                    line-height: 28px;
                    color: $white;
                    user-select: none;
                    @include mobile {
                        font-size: 12px;
                        line-height: 16px;
                        top: 12px;
                        left: 12px;
                    }
                }
                .play_button{
                    position: absolute;
                    top: 52%;
                    left: 52%;
                    transform: translate(-52%, -52%);
                    width: 98px;
                    cursor: pointer;
                    @include mobile {
                        width: 47px;
                    }
                    svg{
                        width: 100%;
                        aspect-ratio: 1/1;
                        g{
                            width: 100%;
                        }
                    }
                }
                .frame{
                    border-radius: 3px;
                    width: 100%;
                    height: 100%;
                    .onePlayer-overlay{
                        background-color: rgba(0,0,0);
                    }
                }
            }
        }
    }
}