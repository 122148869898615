@import './variables';
@import './mixins';

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup {
    background: white;
    width: 80%;
    max-width: 800px; /* Ajusta el ancho máximo según sea necesario */
    border: 0px solid #ccc; /* Borde gris clarito */
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.popup_body {
    text-align: justify; /* Texto justificado a la izquierda */
    margin: 10px; /* Espacio entre párrafos */
    white-space: pre-wrap;
    font-family: 'Gotham-Book';
    font-style: normal;
    font-weight: 325;
    font-size: 14px;
    line-height: 18px;
    color: $grey-05;
    text-transform: none;

    .body {
        span {
            color: $black;
            font-style: italic;
        }

        a:hover span {
            text-decoration: underline; /* Subraya el texto del span cuando el ratón está encima del enlace */
        }
    }
}

.popup_title {
    background-color: rgba(0, 0, 0, 0.6); /* Fondo negro con 60% de transparencia */
    border-radius: 5px;

    .title {
        padding: 5px;
        color: white; /* Color del texto */
    }
}


.popup label {
    display: flex;
    align-items: center; /* Alinea verticalmente el contenido del label (checkbox y texto) */
    margin-bottom: 10px; /* Espacio entre el checkbox y los botones */
    input {
        margin-right: 5px; /* Espacio a la derecha del checkbox */
    }

    span {
        font-style: italic;
    }
}

label a:hover span {
    text-decoration: underline; /* Subraya el texto del span cuando el ratón está encima del enlace */
}


.popup-buttons {
    display: flex;
    justify-content: center;
}

.popup-buttons button {
    margin: 0 10px;
    border-radius: 5px;
}
