@import './variables.scss';
@import './mixins.scss';

.page_layout{
  .banner {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: calc(404px - 70px);
    position: relative;
    @include mobile {
      height: 180px;
    }
    .filter_layer {
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }
    .content_layer {
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      .banner_title {
        max-width: 1440px;
        margin: 0 auto;
        padding: 0 1rem;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        @include laptop {
          max-width: 1200px;
        }
        @include mobile {
          max-width: 100%;
          align-items: start;
          justify-content: space-between
        }
        h1 {
          @include heading-01;
          font-weight: 325;
          font-size: 36px;
          color: $white;
          margin: 0;
          padding-top: 100px;
          margin-bottom: 20px;
          @include mobile {
            padding-top: 20px;
            margin-bottom: 20px;
            font-size: 26px;
            line-height: 32px;
          }
        }
        .breadcrum {
          display: flex;
          flex-direction: row;
          gap: 8px;
          @include mobile {
            margin-bottom: 60px;
          }
          span {
            color: $white;
            font-size: 13px;
            line-height: 24px;
            font-family: 'Gotham-Light';
            cursor: pointer;
            @include mobile {
              font-size: 11px;
              line-height: 16px;
            }
            a {
              color: $white;
              text-decoration: none;
            }
          }
          .current {
            font-family: 'Gotham-Book';
            font-weight: 500;
            cursor: default;
          }
        }
      }
    }
  }
  .main_content{
    max-width: 1440px;
    margin: -3rem auto 0 auto;
    padding: 0 1rem;
    position: relative;
    z-index: 30;
    @include laptop {
        max-width: 1200px;
    }
  }
}
